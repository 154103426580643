import {
  GridItem,
  GridItemProps,
} from '@rsa-digital/evo-shared-components/components/Grid';
import { ComponentProps } from '@rsa-digital/evo-shared-components/helpers/componentProps';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import {
  PageTitle,
  trackCoverOptionsSelection,
  trackCoverTypeSelection,
} from 'helpers/eventTracking';
import { useCurrentQuote, useUpdateQuoteOptions } from 'helpers/useCurrentQuote';
import useQuestionProcessor from 'helpers/useQuestionProcessor';
import {
  CoverDuration,
  initialQuoteOptions,
  LifetimeOption,
  PlusCoverFeeLimit,
  PremierCoverFeeLimit,
} from 'state/formData/quoteOptions';
import { CsQuestion } from 'types/contentStack';
import { StyledGrid, StyledQuestionField, StyledRadioInputCards } from './styles';

type LifetimeOptionQuestionProps = {
  errorText?: string;
} & ComponentProps;

type CsLifetimeOption = {
  csPetQuoteSummaryDemandsAndNeedsQuestionsV2: {
    lifetime_option: CsQuestion & {
      annual_accident_or_illness_limit: {
        card_title: string;
        card_rich_text: string;
      };
      total_annual_limit: {
        card_title: string;
        card_rich_text: string;
      };
      option_labels: {
        selected: string;
        unselected: string;
      };
    };
  };
};

const query = graphql`
  query {
    csPetQuoteSummaryDemandsAndNeedsQuestionsV2 {
      lifetime_option {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
        }
        annual_accident_or_illness_limit {
          card_title
          card_rich_text
        }
        total_annual_limit {
          card_title
          card_rich_text
        }
        option_labels {
          selected
          unselected
        }
      }
    }
  }
`;

export const lifetimeOptionsRadioButtonId = 'lifetimeOptionsRadioButtonId';

const LifetimeOptionQuestion: React.FC<LifetimeOptionQuestionProps> = ({ errorText }) => {
  const {
    csPetQuoteSummaryDemandsAndNeedsQuestionsV2: { lifetime_option },
  } = useStaticQuery<CsLifetimeOption>(query);
  const { processQuestion } = useQuestionProcessor(PageTitle.QuoteSummary);
  const lifeOptionQuestion = processQuestion(lifetime_option);

  const updateQuoteOptions = useUpdateQuoteOptions();
  const quote = useCurrentQuote();
  const quoteOptions = quote?.quoteOptions ?? initialQuoteOptions;
  const { lifetimeOption } = quoteOptions;

  const questionFieldGridItemProps: GridItemProps = {
    desktop: 10,
    tabletLandscape: 10,
    tabletPortrait: 8,
  };

  return (
    <StyledGrid>
      <GridItem desktop={10} tabletLandscape={10}>
        <StyledQuestionField
          question={lifeOptionQuestion}
          errorText={errorText}
          gridItemProps={questionFieldGridItemProps}>
          <StyledRadioInputCards
            id={lifetimeOptionsRadioButtonId}
            value={lifetimeOption}
            options={[
              {
                name:
                  lifetimeOption === LifetimeOption.Annual_Accident_Or_Illness_Limit
                    ? lifetime_option.option_labels.selected
                    : lifetime_option.option_labels.unselected,
                value: LifetimeOption.Annual_Accident_Or_Illness_Limit,
                cardTitle: lifetime_option.annual_accident_or_illness_limit.card_title,
                cardRichText:
                  lifetime_option.annual_accident_or_illness_limit.card_rich_text,
              },
              {
                name:
                  lifetimeOption === LifetimeOption.Total_Annual_Limit
                    ? lifetime_option.option_labels.selected
                    : lifetime_option.option_labels.unselected,
                value: LifetimeOption.Total_Annual_Limit,
                cardTitle: lifetime_option.total_annual_limit.card_title,
                cardRichText: lifetime_option.total_annual_limit.card_rich_text,
              },
            ]}
            onChange={(e) => {
              updateQuoteOptions({
                lifetimeOption: e.target.value as LifetimeOption,
              });
              trackCoverOptionsSelection(
                e.target.value as CoverDuration | LifetimeOption
              );
              trackCoverTypeSelection(
                e.target.value as
                  | LifetimeOption
                  | PlusCoverFeeLimit
                  | PremierCoverFeeLimit
              );
            }}
          />
        </StyledQuestionField>
      </GridItem>
    </StyledGrid>
  );
};

export default LifetimeOptionQuestion;
