import { generateKeyForElement } from '@rsa-digital/evo-shared-components/helpers/elementKeyHelper';
import { QuoteResponsePetInfo } from 'apiHelpers/quote/quoteResponse';
import { isPet9YearsOrOlderOnDate } from 'businessLogic/petAge';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { PageTitle } from 'helpers/eventTracking';
import { petPlaceholders } from 'helpers/placeholders/petPlaceholders';
import { quotePlaceholders } from 'helpers/placeholders/quotePlaceholders';
import { replacePlaceholdersRichText } from 'helpers/placeholders/replaceCsPlaceholders';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import { ExcessStatementWrapper, LargeRichTextWithModal } from './styles';
import { SummaryText } from './useSummaryText';

type SummaryOfYourNeedsTextSectionProps = {
  summaryText: SummaryText;
  pageTitle: PageTitle;
};

type CsSummaryOfNeedsTextSection = {
  csPetQuoteSummaryOfYourNeedsV2: {
    text_summary: {
      per_pet_excess_statement: string;
      per_pet_over_nine_excess_statement: string;
    };
  };
};

const query = graphql`
  query {
    csPetQuoteSummaryOfYourNeedsV2 {
      text_summary {
        per_pet_excess_statement
        per_pet_over_nine_excess_statement
      }
    }
  }
`;

const SummaryOfYourNeedsTextSection: React.FC<SummaryOfYourNeedsTextSectionProps> = ({
  summaryText,
  pageTitle,
}) => {
  const { per_pet_excess_statement, per_pet_over_nine_excess_statement } = useStaticQuery<
    CsSummaryOfNeedsTextSection
  >(query).csPetQuoteSummaryOfYourNeedsV2.text_summary;
  const quote = useCurrentQuote();

  const substituteQuotePlacholders = replacePlaceholdersRichText(
    quotePlaceholders,
    quote
  );

  const coverStartDate = quote.policyInfo
    ? new Date(quote.policyInfo.coverStartDate)
    : new Date();

  const substituteExcessStatementPetPlaceholders = (
    pet: QuoteResponsePetInfo
  ): string => {
    if (isPet9YearsOrOlderOnDate(pet.dob, coverStartDate)) {
      return replacePlaceholdersRichText(
        petPlaceholders,
        pet
      )(per_pet_over_nine_excess_statement);
    }
    return replacePlaceholdersRichText(petPlaceholders, pet)(per_pet_excess_statement);
  };

  return (
    <>
      <LargeRichTextWithModal
        pageTitle={pageTitle}
        html={substituteQuotePlacholders(summaryText.text_before_statement)}
      />
      <ExcessStatementWrapper>
        {quote.petInfos?.map((pet) => (
          <LargeRichTextWithModal
            pageTitle={pageTitle}
            key={generateKeyForElement(pet)}
            html={substituteExcessStatementPetPlaceholders(pet)}
          />
        ))}
      </ExcessStatementWrapper>
      <LargeRichTextWithModal
        pageTitle={pageTitle}
        html={substituteQuotePlacholders(summaryText.text_after_statement)}
      />
    </>
  );
};

export default SummaryOfYourNeedsTextSection;
