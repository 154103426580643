import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const ExcessAmount = styled.p`
  ${fonts.headingMedium};
  margin: ${spacing(3)} 0 ${spacing(2)};
`;

export const ExcessRichText = styled(RichText)`
  margin: ${spacing(2)} 0 0;
`;

export const ExcessSegmentContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
`;

export const ExcessSegmentTextContainer = styled.div`
  text-align: center;
  padding-bottom: ${spacing(2)};
`;

export const ExcessBar = styled.div`
  height: ${spacing(2)};
  width: 100%;
  background-color: ${colors.core03};
`;
