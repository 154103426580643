import { FlexibleWidthDivider } from '@rsa-digital/evo-shared-components/components/Divider';
import RadioInput from '@rsa-digital/evo-shared-components/components/Form/RadioInput';
import { Orientation } from '@rsa-digital/evo-shared-components/components/Form/RadioInput/styles';
import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import LoadingOverlayV2 from '@rsa-digital/evo-shared-components/components/LoadingOverlayV2';
import PriceBannerV2 from '@rsa-digital/evo-shared-components/components/PriceBannerV2';
import useValidation from '@rsa-digital/evo-shared-components/helpers/forms/useValidation';
import {
  getCoverLevelFromQuoteOptions,
  isQuoteOptionSelectionValid,
} from 'apiHelpers/quote/bundleCoverMapping';
import useUpdateQuoteCover from 'apiHelpers/quote/useUpdateQuoteCover';
import { AxiosError } from 'axios';
import { graphql, navigate } from 'gatsby';
import React, {
  MouseEventHandler,
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import AddAnotherPetModal from 'components/AddAnotherPetModal';
import AggregatorAgreements from 'components/Aggregators/AggregatorAgreements';
import AggregatorSummary from 'components/Aggregators/AggregatorSummary';
import LoadQuoteWrapper from 'components/ApiRequestWrapper/LoadQuoteWrapper';
import FormFooter from 'components/FormFooter';
import Layout from 'components/Layout';
import { BannerContainer } from 'components/Layout/styles';
import PricingOverviewPanel from 'components/PricingOverviewPanel';
import { QuoteAndBuyStep } from 'components/ProgressBar';
import AdditionalLifetimeProductsQuestion from 'components/QuoteSummary/AdditionalLifetimeProducts';
import ChooseExcessSection from 'components/QuoteSummary/ChooseExcessSection';
import LifetimeOptionQuestion from 'components/QuoteSummary/LifetimeOptionQuestion';
import PlusPremierComparisonModal from 'components/QuoteSummary/PlusPremierComparisonModal';
import { PriceBannerText } from 'components/QuoteSummary/PriceBannerText';
import SummaryOfCover from 'components/QuoteSummary/SummaryOfCover';
import AdditionalQuestionForEssential from 'components/QuoteSummary/SummaryOfYourNeeds/AdditionalQuestionForEssential';
import { useAdditionalQuestionEssentialRules } from 'components/QuoteSummary/SummaryOfYourNeeds/AdditionalQuestionForEssential/validation';
import AdditionalQuestionSection from 'components/QuoteSummary/SummaryOfYourNeeds/AdditionalQuestionSection';
import { displayAdditionalQuestion } from 'components/QuoteSummary/SummaryOfYourNeeds/AdditionalQuestionSection/helpers';
import { useAdditionalQuestionRules } from 'components/QuoteSummary/SummaryOfYourNeeds/AdditionalQuestionSection/validation';
import InfoBoxSection from 'components/QuoteSummary/SummaryOfYourNeeds/InfoBoxSection';
import SummaryOfYourNeedsTextSection from 'components/QuoteSummary/SummaryOfYourNeeds/SummaryOfYourNeedsTextSection';
import { useSummaryText } from 'components/QuoteSummary/SummaryOfYourNeeds/SummaryOfYourNeedsTextSection/useSummaryText';
import { SessionExpiryOption } from 'components/SessionExpiry/SessionExpiryWrapper';
import TreatmentCostModal from 'components/TreatmentCostModal';
import WhyChooseUsSection from 'components/WhyChooseUsSection';
import SectionHeading from 'forms/SectionHeading';
import { ProductId } from 'helpers/businessConstants';
import { unwrapSingleton } from 'helpers/csTypeProcessors';
import { trackCheckoutEvent } from 'helpers/ecommerceTracking';
import useDefaultErrorHandling from 'helpers/errorHandling';
import {
  PageTitle,
  trackCoverOptionsSelection,
  trackFieldError,
  trackModalOpen,
  trackTextButtonClick,
} from 'helpers/eventTracking';
import { scrollAndTrackError } from 'helpers/forms';
import { usePageTracking } from 'helpers/pageTracking';
import { quotePlaceholders } from 'helpers/placeholders/quotePlaceholders';
import {
  noOpPlaceholderReplacer,
  replacePlaceholdersPlainText,
  replacePlaceholdersQuestion,
} from 'helpers/placeholders/replaceCsPlaceholders';
import { formatInPoundsWithSign } from 'helpers/poundsHelpers';
import { getSelectedProductTotalPrice } from 'helpers/priceHelper';
import { getProductFromQuoteOptions, Product } from 'helpers/productHelpers';
import { addItem } from 'helpers/repeatableForm';
import { quoteAndBuyRoutes } from 'helpers/routingHelper';
import { trySaveQuote, useSaveAndEmailQuote } from 'helpers/saveQuoteHelpers';
import { useCurrentQuote, useUpdateQuoteOptions } from 'helpers/useCurrentQuote';
import useQuestionProcessor from 'helpers/useQuestionProcessor';
import useScrollCallback from 'helpers/useScrollCallback';
import { initialPet, usePetsDetails } from 'state/formData/petsDetails';
import {
  CoverDuration,
  initialQuoteOptions,
  LifetimeOption,
  QuoteOptions,
} from 'state/formData/quoteOptions';
import useReferenceData from 'state/referenceData/useReferenceData';
import { CsHero, CsIcon, CsModal, CsQuestion } from 'types/contentStack';
import {
  ComparisonModalButtonLink,
  FlexibleWidthDividerWithMarginTop,
  InfoPanelWithMargins,
  PlusOrPremierCoverHeading,
  PriceBannerSpace,
  QuestionFieldWithMargin,
  RichTextLargeWithMargins,
  SetYourExcessSectionText,
  StyledBannerWithAddButton,
  StyledParagraph,
  StyledSectionHeading,
} from './styles';
import useQuoteSummaryRules from './validation';

const SCROLL_UPDATE_RATE_MS = 50;
const PAGE_LANDING_STEP = 1;

const findingTheRightCoverSectionId = 'finding-the-right-cover-section';
export const coverDurationRadioButtonId = 'coverDurationRadioButton';

type CoverDurationInfoBoxDetails = {
  included_features_text: string;
  not_included_features_text: string;
  description_text: string;
};

type QuoteSummaryProps = {
  data: {
    csPetQuoteSummaryV2: {
      meta_title: string;
      hero: CsHero;
      next_button_text: string;
      finding_the_right_cover_section_heading: string;
      finding_the_right_cover_section_description: string;
      set_your_excess_section: {
        heading: string;
        essential_cover_body: string;
        standard_cover_body: string;
        plus_cover_body: string;
        premier_cover_body: string;
      };
      summary_of_your_needs_section_heading: string;
      successful_save_modal: [CsModal] | [];
      price_banner: {
        monthly_label: string;
        annual_label: string;
        cover_type: string;
        excess_amount: string;
        vet_fees_limit: string;
        vet_fees_limit_others: string;
        view_your_excesses: string;
      };
      add_another_pet_promo_box: {
        icon: [CsIcon];
        heading: string;
        less_than_three_pets_information: string;
        three_pets_information: string;
        add_another_pet_button_text: string;
        add_another_pet_button_screenreader_text: string;
      };
    };
    csPetQuoteSummarySelectCover: {
      cover_table_intro: {
        time_limited: string;
        lifetime: string;
      };
      missing_product_error_panel: string;
    };
    csPetQuoteSummaryDemandsAndNeedsQuestionsV2: {
      cover_duration: CsQuestion & {
        option_labels: {
          time_limited: string;
          lifetime: string;
        };
      };
      cover_duration_details: {
        included_bullet_icon: [CsIcon] | [];
        not_included_bullet_icon: [CsIcon] | [];
        time_limited: CoverDurationInfoBoxDetails;
        lifetime: CoverDurationInfoBoxDetails;
      };
      plus_and_premier_cover_details: {
        modal_link: {
          link_text: string;
          modal: [CsModal] | [];
        };
      };
    };
    csPetSummaryOfCover: {
      plus_or_premier_cover_heading: string;
    };
    treatmentCostLink?: string;
    treatmentCostOnClick: MouseEventHandler;
    csPetGlobalConfig: {
      loading_spinner: {
        email_spinner_text: string;
        excess_spinner_text: string;
        continue_spinner_text: string;
      };
    };
  };
};

export const query = graphql`
  query {
    csPetQuoteSummaryV2 {
      meta_title
      hero {
        heading
        subheading
      }
      finding_the_right_cover_section_heading
      finding_the_right_cover_section_description
      set_your_excess_section {
        heading
        essential_cover_body
        standard_cover_body
        plus_cover_body
        premier_cover_body
      }
      summary_of_your_needs_section_heading
      add_another_pet_promo_box {
        icon {
          icon_code
        }
        heading
        three_pets_information
        less_than_three_pets_information
        add_another_pet_button_text
        add_another_pet_button_screenreader_text
      }
      next_button_text
      successful_save_modal {
        modal_id
      }
      price_banner {
        monthly_label
        annual_label
        cover_type
        excess_amount
        vet_fees_limit
        vet_fees_limit_others
        view_your_excesses
      }
    }
    csPetQuoteSummarySelectCover {
      cover_table_intro {
        time_limited
        lifetime
      }
      missing_product_error_panel
    }
    csPetQuoteSummaryDemandsAndNeedsQuestionsV2 {
      cover_duration {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
        }
        option_labels {
          time_limited
          lifetime
        }
      }
      cover_duration_details {
        included_bullet_icon {
          icon_code
        }
        not_included_bullet_icon {
          icon_code
        }
        time_limited {
          included_features_text
          not_included_features_text
          description_text
        }
        lifetime {
          included_features_text
          not_included_features_text
          description_text
        }
      }
      plus_and_premier_cover_details {
        modal_link {
          link_text
          modal {
            modal_id
          }
        }
      }
    }
    csPetSummaryOfCover {
      plus_or_premier_cover_heading
    }
    csPetGlobalConfig {
      loading_spinner {
        email_spinner_text
        excess_spinner_text
        continue_spinner_text
      }
    }
  }
`;

const QuoteSummary: React.FC<QuoteSummaryProps> = ({
  data: {
    csPetQuoteSummaryV2: {
      meta_title,
      hero,
      next_button_text,
      finding_the_right_cover_section_heading,
      finding_the_right_cover_section_description,
      set_your_excess_section,
      summary_of_your_needs_section_heading,
      price_banner,
      add_another_pet_promo_box,
    },
    csPetQuoteSummaryDemandsAndNeedsQuestionsV2: {
      cover_duration,
      cover_duration_details: {
        included_bullet_icon,
        not_included_bullet_icon,
        time_limited,
        lifetime,
      },
      plus_and_premier_cover_details: {
        modal_link: { link_text },
      },
    },
    csPetSummaryOfCover: { plus_or_premier_cover_heading },
    csPetGlobalConfig: {
      loading_spinner: { email_spinner_text, excess_spinner_text, continue_spinner_text },
    },
  },
}) => {
  const quote = useCurrentQuote();

  const updateQuoteOptions = useUpdateQuoteOptions();

  const quoteUpdaters = useUpdateQuoteCover();
  const excessSectionId = 'excess-section';
  const summaryOfYourNeedsSectionId = 'summary-of-your-needs-section';

  const quoteOptions = quote?.quoteOptions ?? initialQuoteOptions;
  const product = getProductFromQuoteOptions(quote.quoteOptions);
  const productIsEssential = product === Product.Essential;

  const { coverDuration, lifetimeOption } = quoteOptions;

  const coverDurationDetails =
    coverDuration === CoverDuration.Time_Limited ? time_limited : lifetime;

  const { isAnnualPayment } = quoteOptions;

  const [showStickyBanner, setShowStickyBanner] = useState<boolean>(false);

  const [isContinueSelect, setIsContinueSelect] = useState<boolean>(false);
  const summaryOfCoverRef = useRef<HTMLDivElement>(null);

  const totalSelectedPrice = getSelectedProductTotalPrice(quote, isAnnualPayment);

  const updateScrollCallback = useCallback(() => {
    if (!summaryOfCoverRef.current) {
      return;
    }
    setShowStickyBanner(!!totalSelectedPrice);
  }, [totalSelectedPrice]);

  useScrollCallback(updateScrollCallback, SCROLL_UPDATE_RATE_MS);

  const { processQuestion } = useQuestionProcessor(PageTitle.QuoteSummary);

  const plainTextPlaceholderReplacer = quote
    ? replacePlaceholdersPlainText(quotePlaceholders, quote, true)
    : noOpPlaceholderReplacer;

  const substituteQuoteQuestionPlaceholders = replacePlaceholdersQuestion(
    plainTextPlaceholderReplacer
  );

  const coverDurationQuestion = substituteQuoteQuestionPlaceholders(
    processQuestion(cover_duration)
  );

  const [showTreatmentCostModal, toggleTreatmentCostModal] = useReducer(
    (state) => !state,
    false
  );

  const [showPlusPremierComparisonModal, togglePlusPremierComparisonModal] = useReducer(
    (state) => !state,
    false
  );

  const [showAddAnotherPetModal, toggleAddAnotherPetModal] = useReducer(
    (state) => !state,
    false
  );

  const catBreedsRefData = useReferenceData('catBreeds')?.catBreeds ?? [];
  const dogBreedsRefData = useReferenceData('dogBreeds')?.dogBreeds ?? [];

  const selectedCoverLevel = getCoverLevelFromQuoteOptions(quote.quoteOptions);
  useEffect(() => {
    if (selectedCoverLevel) {
      trackCheckoutEvent(
        PAGE_LANDING_STEP,
        quote,
        catBreedsRefData,
        dogBreedsRefData,
        product
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCoverLevel]);

  const numberOfPetsInQuote = quote.petInfos?.length || 0;

  const numberOfPetsInHousehold =
    quote.policyInfo?.numberPetInHousehold || numberOfPetsInQuote;

  const [petsDetails, updatePetsDetails] = usePetsDetails();

  const defaultErrorHandling = useDefaultErrorHandling();

  const rules = useQuoteSummaryRules();
  const additionalQuestionRules = useAdditionalQuestionRules();
  const additionalQuestionEssentialRules = useAdditionalQuestionEssentialRules();

  const formDetails: QuoteOptions = {
    ...quote?.quoteOptions,
  };

  const formValidation = useValidation(
    formDetails,
    { ...rules, ...additionalQuestionRules, ...additionalQuestionEssentialRules },
    trackFieldError
  );
  const { validateOnSubmit, getError, showValidation } = formValidation;

  const moveNext = async (): Promise<void> => {
    try {
      await trySaveQuote({
        onSuccess: () => navigate(quoteAndBuyRoutes.checkYourDetails),
        quoteUpdaters,
      });
    } catch (err) {
      const error: AxiosError = err as AxiosError;
      defaultErrorHandling(error);
    }
  };

  const {
    saveAndEmailQuote,
    isSaveInProgress,
    savedQuoteConfirmationModal,
  } = useSaveAndEmailQuote(PageTitle.QuoteSummary);

  const isAggsQuote = quote.productId !== ProductId.DIRECT;

  usePageTracking(meta_title, !!quote.customerInfo);

  const summaryText = useSummaryText();

  const getCoverLevelSpecificText = (quoteOptionsParam: QuoteOptions): string => {
    const productType = getProductFromQuoteOptions(quoteOptionsParam);
    switch (productType) {
      case Product.Essential:
        return set_your_excess_section.essential_cover_body;
      case Product.Standard:
        return set_your_excess_section.standard_cover_body;
      case Product.Plus:
        return set_your_excess_section.plus_cover_body;
      case Product.Premier:
        return set_your_excess_section.premier_cover_body;
      default:
        return '';
    }
  };

  const displayExcessSection = (currentQuoteOptions: QuoteOptions): boolean =>
    isQuoteOptionSelectionValid(currentQuoteOptions);

  const showAddAdditionalPetsQuestion =
    (numberOfPetsInHousehold > numberOfPetsInQuote && numberOfPetsInQuote < 3) ||
    (isAggsQuote && numberOfPetsInQuote === 1 && numberOfPetsInHousehold === 1);

  const loadingMsgText = (): string => {
    if (isSaveInProgress) return email_spinner_text;
    if (isContinueSelect) return continue_spinner_text;
    return excess_spinner_text;
  };

  return (
    <LoadQuoteWrapper>
      <Layout
        metaTitle={meta_title}
        currentStep={QuoteAndBuyStep.QuoteSummary}
        pageTitle={PageTitle.QuoteSummary}
        heading={hero.heading}
        subheading={hero.subheading}
        sessionExpiryOption={
          isQuoteOptionSelectionValid(quoteOptions)
            ? SessionExpiryOption.EMAIL_QUOTE_SESSION_EXPIRY
            : undefined
        }>
        {(quoteUpdaters?.isLoading || isSaveInProgress) && (
          <LoadingOverlayV2 loadingMessage={loadingMsgText()} timeDuration={10} />
        )}
        <form onSubmit={validateOnSubmit(moveNext, scrollAndTrackError, true)}>
          {isAggsQuote && (
            <AggregatorSummary
              aggregator={quote.productId}
              pageTitle={PageTitle.QuoteSummary}
            />
          )}
          <section aria-labelledby={findingTheRightCoverSectionId}>
            <StyledSectionHeading
              heading={plainTextPlaceholderReplacer(
                finding_the_right_cover_section_heading
              )}
              id={findingTheRightCoverSectionId}
            />
            <StyledParagraph>
              {finding_the_right_cover_section_description}
            </StyledParagraph>
            <QuestionFieldWithMargin
              question={coverDurationQuestion}
              errorText={plainTextPlaceholderReplacer(getError('coverDuration') ?? '')}>
              <RadioInput
                id={coverDurationRadioButtonId}
                value={coverDuration}
                orientationOverride={Orientation.vertical}
                options={[
                  {
                    name: cover_duration.option_labels.time_limited,
                    value: CoverDuration.Time_Limited,
                  },
                  {
                    name: cover_duration.option_labels.lifetime,
                    value: CoverDuration.Lifetime,
                  },
                ]}
                onChange={(e) => {
                  updateQuoteOptions({
                    coverDuration: e.target.value as CoverDuration,
                  });
                  trackCoverOptionsSelection(
                    e.target.value as CoverDuration | LifetimeOption
                  );
                }}
              />
            </QuestionFieldWithMargin>
            {coverDuration && (
              <Grid alignLeft>
                <GridItem desktop={6} tabletLandscape={6} tabletPortrait={6}>
                  <InfoPanelWithMargins data-cy="coverDurationDetails">
                    {coverDurationDetails.included_features_text && (
                      <RichTextLargeWithMargins
                        html={coverDurationDetails.included_features_text}
                        customBulletIcon={
                          unwrapSingleton(included_bullet_icon)?.icon_code
                        }
                        pageTitle={PageTitle.QuoteSummary}
                      />
                    )}
                    {coverDurationDetails.not_included_features_text && (
                      <RichTextLargeWithMargins
                        html={coverDurationDetails.not_included_features_text}
                        customBulletIcon={
                          unwrapSingleton(not_included_bullet_icon)?.icon_code
                        }
                        pageTitle={PageTitle.QuoteSummary}
                      />
                    )}
                    {coverDurationDetails.description_text && (
                      <RichTextLargeWithMargins
                        html={coverDurationDetails.description_text}
                        pageTitle={PageTitle.QuoteSummary}
                      />
                    )}
                  </InfoPanelWithMargins>
                </GridItem>
              </Grid>
            )}
            {coverDuration === CoverDuration.Lifetime && (
              <LifetimeOptionQuestion errorText={getError('lifetimeOption')} />
            )}
            {lifetimeOption === LifetimeOption.Total_Annual_Limit &&
              coverDuration !== CoverDuration.Time_Limited && (
                <>
                  <PlusOrPremierCoverHeading>
                    {plus_or_premier_cover_heading}
                  </PlusOrPremierCoverHeading>
                  <AdditionalLifetimeProductsQuestion
                    errorText={getError('plusCoverFeeLimit')}
                  />
                  <ComparisonModalButtonLink
                    onClick={() => {
                      trackModalOpen('Plus and Premier Comparison Modal');
                      togglePlusPremierComparisonModal();
                    }}>
                    {link_text}
                  </ComparisonModalButtonLink>
                </>
              )}
            {product && (
              <SummaryOfCover
                pageTitle={PageTitle.QuoteSummary}
                product={product}
                summaryOfCoverRef={summaryOfCoverRef}
              />
            )}
          </section>
          {product && displayAdditionalQuestion(quoteOptions) && (
            <AdditionalQuestionSection
              formValidation={{ getError }}
              pageTitle={PageTitle.QuoteSummary}
              product={product}
            />
          )}
          {displayExcessSection(quoteOptions) && (
            <>
              {showAddAdditionalPetsQuestion && (
                <StyledBannerWithAddButton
                  data-cy="addAnotherPetBanner"
                  pageTitle={PageTitle.QuoteSummary}
                  headingText={add_another_pet_promo_box.heading}
                  bodyText={
                    numberOfPetsInQuote < 3
                      ? add_another_pet_promo_box.less_than_three_pets_information
                      : add_another_pet_promo_box.three_pets_information
                  }
                  gridItemProps={{
                    desktop: 10,
                    tabletLandscape: 10,
                    tabletPortrait: 8,
                  }}
                  buttonText={add_another_pet_promo_box.add_another_pet_button_text}
                  buttonAriaLabel={add_another_pet_promo_box.add_another_pet_button_text}
                  buttonId="addPetButtonModal"
                  addFormSectionButtonClick={
                    numberOfPetsInQuote < 3
                      ? () => {
                          toggleAddAnotherPetModal();
                          trackModalOpen('addAnotherPetModal');
                        }
                      : undefined
                  }
                  icon={unwrapSingleton(add_another_pet_promo_box.icon)?.icon_code}
                />
              )}
              <section aria-labelledby={excessSectionId} data-cy="excessSection">
                <SectionHeading
                  heading={set_your_excess_section.heading}
                  id={excessSectionId}
                />
                <SetYourExcessSectionText
                  pageTitle={PageTitle.QuoteSummary}
                  html={getCoverLevelSpecificText(quote.quoteOptions)}
                />
                <ChooseExcessSection
                  data-cy="chooseExcessSection"
                  quoteUpdaters={quoteUpdaters}
                  pageTitle={PageTitle.QuoteSummary}
                  formValidation={formValidation}
                />
              </section>
              <section aria-labelledby={summaryOfYourNeedsSectionId}>
                <FlexibleWidthDivider />
                <SectionHeading
                  heading={summary_of_your_needs_section_heading}
                  id={summaryOfYourNeedsSectionId}
                />
                {summaryText && (
                  <SummaryOfYourNeedsTextSection
                    pageTitle={PageTitle.QuoteSummary}
                    summaryText={summaryText}
                  />
                )}
                {productIsEssential && (
                  <AdditionalQuestionForEssential
                    formValidation={{ getError, showValidation }}
                  />
                )}
                <FlexibleWidthDividerWithMarginTop />
                <InfoBoxSection />
                <FlexibleWidthDivider />
                <Grid alignLeft>
                  <GridItem desktop={9} tabletLandscape={9}>
                    <WhyChooseUsSection
                      pageTitle={PageTitle.QuoteSummary}
                      desktop={9}
                      tabletLandscape={9}
                    />
                  </GridItem>
                </Grid>
                <section aria-label="Payment Overview">
                  <PricingOverviewPanel
                    data-cy="pricingOverviewPanel"
                    pageTitle={PageTitle.QuoteSummary}
                  />
                </section>
              </section>
              {isAggsQuote && <AggregatorAgreements pageTitle={PageTitle.QuoteSummary} />}
            </>
          )}
          <Grid alignLeft>
            <GridItem
              desktop={coverDuration ? 10 : 6}
              tabletLandscape={coverDuration ? 10 : 6}
              tabletPortrait={coverDuration ? 8 : 6}>
              <FormFooter
                backButton={{
                  onClick: () => {
                    trackTextButtonClick(PageTitle.QuoteSummary, 'Back');
                    navigate(quoteAndBuyRoutes.aboutYouAndYourPet);
                  },
                }}
                moveNextButton={{
                  text: next_button_text,
                  onClick: () => {
                    trackTextButtonClick(PageTitle.QuoteSummary, 'Continue');
                    setIsContinueSelect(true);
                  },
                }}
                saveButton={
                  isQuoteOptionSelectionValid(quoteOptions)
                    ? {
                        onClick: saveAndEmailQuote,
                      }
                    : undefined
                }
                printButton={
                  isQuoteOptionSelectionValid(quoteOptions)
                    ? {
                        onClick: () => window.print(),
                      }
                    : undefined
                }
                pageTitle={PageTitle.QuoteSummary}
              />
            </GridItem>
          </Grid>
        </form>
        {savedQuoteConfirmationModal}
        {showTreatmentCostModal && (
          <TreatmentCostModal
            onClose={toggleTreatmentCostModal}
            pageTitle={PageTitle.QuoteSummary}
          />
        )}
        {showAddAnotherPetModal && (
          <AddAnotherPetModal
            data-cy="addAnotherPetModal"
            onClose={toggleAddAnotherPetModal}
            onConfirm={() => {
              updatePetsDetails(addItem(petsDetails, initialPet));
              navigate(
                `${quoteAndBuyRoutes.aboutYouAndYourPet}#petDetails[${numberOfPetsInQuote}]`
              );
              toggleAddAnotherPetModal();
            }}
            pageTitle={PageTitle.QuoteSummary}
          />
        )}
        {showPlusPremierComparisonModal && (
          <PlusPremierComparisonModal
            data-cy="plusPremierComparisonModal"
            onClose={togglePlusPremierComparisonModal}
          />
        )}
        {totalSelectedPrice && (
          <BannerContainer>
            <PriceBannerV2
              show={showStickyBanner}
              price={formatInPoundsWithSign(totalSelectedPrice)}
              priceLabel={
                isAnnualPayment ? price_banner.annual_label : price_banner.monthly_label
              }>
              <PriceBannerText />
            </PriceBannerV2>
          </BannerContainer>
        )}
      </Layout>
      {showStickyBanner && <PriceBannerSpace />}
    </LoadQuoteWrapper>
  );
};
export default QuoteSummary;
