import { StyledPredefinedPanel } from '@rsa-digital/evo-shared-components/components/Form/Field/ErrorContainer/styles';
import GroupedRadioInput from '@rsa-digital/evo-shared-components/components/Form/RadioInput/GroupedRadioInput';
import { RadioInputGroupContainer } from '@rsa-digital/evo-shared-components/components/Form/RadioInput/GroupedRadioInput/styles';
import { GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing, {
  spacingWithBorder,
} from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const Heading = styled.h3`
  margin-top: 0;
  margin-bottom: ${spacing(2)};
  ${fonts.headingMedium};

  ${mediaQuery.tabletPortrait`
    margin-bottom:${spacing(4)};
  `}
`;

export const StyledGridItem = styled(GridItem)`
  ${StyledPredefinedPanel} {
    margin-bottom: ${spacingWithBorder(4, 1)};
  }
`;

export const StyledGroupedRadioInput = styled(GroupedRadioInput)`
  margin-top: ${spacing(2)};
  margin-bottom: 0;

  ${RadioInputGroupContainer} {
    ${mediaQuery.tabletPortrait`
      margin-bottom: 0;
    `};

    &:last-child {
      margin-bottom: 0;
    }
  }
`;
