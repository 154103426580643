import Icon from '@rsa-digital/evo-shared-components/components/Icon';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import componentProps, {
  ComponentProps,
} from '@rsa-digital/evo-shared-components/helpers/componentProps';
import React from 'react';
import RichTextWithModal from 'components/RichTextWithModal';
import { unwrapSingleton } from 'helpers/csTypeProcessors';
import { PageTitle } from 'helpers/eventTracking';
import { OfferPanelBasics } from 'types/contentStack';
import { DiscountPanelContainer, IconWrapper } from './styles';

type DiscountPanelProps = {
  offerPanel: OfferPanelBasics;
  pageTitle: PageTitle;
} & ComponentProps;

const DiscountPanel: React.FC<DiscountPanelProps> = ({
  offerPanel,
  pageTitle,
  ...rest
}) => {
  const offerIcon = unwrapSingleton(offerPanel.icon)?.icon_code;

  return (
    <DiscountPanelContainer {...componentProps(rest)}>
      {offerIcon && (
        <IconWrapper>
          <Icon size="xxx-large" name={offerIcon} color={colors.core01} />
        </IconWrapper>
      )}
      <RichTextWithModal html={offerPanel.content_rich_text} pageTitle={pageTitle} />
    </DiscountPanelContainer>
  );
};

export default DiscountPanel;
