import { StyledLabel } from '@rsa-digital/evo-shared-components/components/Form/Field/FieldInfo/Label/styles';
import RadioInputCards from '@rsa-digital/evo-shared-components/components/Form/RadioInput/RadioInputCards';
import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import QuestionField from 'components/QuestionField';

export const HeadingGridItem = styled(GridItem)`
  margin-top: ${spacing(6)};
`;

export const Heading = styled.h3`
  margin-top: 0;
  margin-bottom: ${spacing(2)};
  ${fonts.headingMedium};

  ${mediaQuery.tabletPortrait`
    margin-bottom:${spacing(4)};
  `}
`;

export const StyledGrid = styled(Grid)`
  margin-top: ${spacing(6)};
`;

export const StyledQuestionField = styled(QuestionField)`
  ${StyledLabel} {
    margin-top: 0;
    margin-bottom: ${spacing(2)};

    ${fonts.headingMedium}

    ${mediaQuery.tabletPortrait`
      margin-bottom: ${spacing(4)};
    `}
  }
`;
export const StyledRadioInputCards = styled(RadioInputCards)`
  label {
    max-width: ${spacing(34)};
    ${mediaQuery.tabletPortrait`
      max-width: ${spacing(32)};
    `}
  }
`;
