import { graphql, useStaticQuery } from 'gatsby';
import { getVetFeeLimit } from 'helpers/getVetFeeLimit';
import { getProductFromQuoteOptions, Product } from 'helpers/productHelpers';
import { petType_CAT, petType_DOG } from 'helpers/referenceDataConstants';
import { CurrentQuote, useCurrentQuote } from 'helpers/useCurrentQuote';
import { PlusCoverFeeLimit, PremierCoverFeeLimit } from 'state/formData/quoteOptions';

type CoverPercentages = {
  essential: number;
  standard: number;
  standard_dog: number;
  standard_cat: number;
  standard_multipet: number;
  plus_2000: number;
  plus_2000_dog: number;
  plus_2000_cat: number;
  plus_2000_multipet: number;
  plus_3000: number;
  plus_3000_dog: number;
  plus_3000_cat: number;
  plus_3000_multipet: number;
  premier_5000: number;
  premier_5000_dog: number;
  premier_5000_cat: number;
  premier_5000_multipet: number;
  premier_7000: number;
  premier_7000_dog: number;
  premier_7000_cat: number;
  premier_7000_multipet: number;
  premier_10000: number;
  premier_10000_dog: number;
  premier_10000_cat: number;
  premier_10000_multipet: number;
};

type CsPercentageOfClaimsCovered = {
  csPetSummaryOfCover: {
    pricing_info: {
      typical_treatment_costs_cover_percentages: CoverPercentages;
    };
  };
};

const query = graphql`
  query {
    csPetSummaryOfCover {
      pricing_info {
        typical_treatment_costs_cover_percentages {
          essential
          standard
          standard_dog
          standard_cat
          standard_multipet
          plus_2000
          plus_2000_dog
          plus_2000_cat
          plus_2000_multipet
          plus_3000
          plus_3000_dog
          plus_3000_cat
          plus_3000_multipet
          premier_5000
          premier_5000_dog
          premier_5000_cat
          premier_5000_multipet
          premier_7000
          premier_7000_dog
          premier_7000_cat
          premier_7000_multipet
          premier_10000
          premier_10000_dog
          premier_10000_cat
          premier_10000_multipet
        }
      }
    }
  }
`;

const checkPetType = (currentQuote: CurrentQuote): string => {
  const uniquePetType = [
    ...new Set(currentQuote?.petInfos?.map((petDetails) => petDetails.petType)),
  ];
  if (uniquePetType.length === 1) {
    return uniquePetType[0];
  }
  return '';
};

const usePercentageOfClaimsCovered = (): number => {
  const cover_percentages = useStaticQuery<CsPercentageOfClaimsCovered>(query)
    .csPetSummaryOfCover.pricing_info.typical_treatment_costs_cover_percentages;
  const quote = useCurrentQuote();
  const product = getProductFromQuoteOptions(quote.quoteOptions);

  const petType = checkPetType(quote);
  const vetFeeLimit = getVetFeeLimit(quote);

  /* istanbul ignore if */
  if (product === Product.Essential) {
    return cover_percentages.essential;
  }

  /* istanbul ignore if */
  if (product === Product.Standard) {
    switch (petType) {
      case petType_DOG:
        return cover_percentages.standard_dog;
      case petType_CAT:
        return cover_percentages.standard_cat;
      default:
        return cover_percentages.standard_multipet;
    }
  }

  /* Covering each of these cases is not valuable */
  /* istanbul ignore if */
  if (product === Product.Plus) {
    if (vetFeeLimit === PlusCoverFeeLimit.Limit_2000) {
      switch (petType) {
        case petType_DOG:
          return cover_percentages.plus_2000_dog;
        case petType_CAT:
          return cover_percentages.plus_2000_cat;
        default:
          return cover_percentages.plus_2000_multipet;
      }
    } else if (vetFeeLimit === PlusCoverFeeLimit.Limit_3000) {
      switch (petType) {
        case petType_DOG:
          return cover_percentages.plus_3000_dog;
        case petType_CAT:
          return cover_percentages.plus_3000_cat;
        default:
          return cover_percentages.plus_3000_multipet;
      }
    } else {
      return 0;
    }
  }

  /* Covering each of these cases is not valuable */
  /* istanbul ignore if */
  if (product === Product.Premier) {
    if (vetFeeLimit === PremierCoverFeeLimit.Limit_5000) {
      switch (petType) {
        case petType_DOG:
          return cover_percentages.premier_5000_dog;
        case petType_CAT:
          return cover_percentages.premier_5000_cat;
        default:
          return cover_percentages.premier_5000_multipet;
      }
    } else if (vetFeeLimit === PremierCoverFeeLimit.Limit_7000) {
      switch (petType) {
        case petType_DOG:
          return cover_percentages.premier_7000_dog;
        case petType_CAT:
          return cover_percentages.premier_7000_cat;
        default:
          return cover_percentages.premier_7000_multipet;
      }
    } else if (vetFeeLimit === PremierCoverFeeLimit.Limit_10000) {
      switch (petType) {
        case petType_DOG:
          return cover_percentages.premier_10000_dog;
        case petType_CAT:
          return cover_percentages.premier_10000_cat;
        default:
          return cover_percentages.premier_10000_multipet;
      }
    } else {
      return 0;
    }
  }

  /* istanbul ignore next */
  return 0;
};

export default usePercentageOfClaimsCovered;
