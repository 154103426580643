import React from 'react';
import { BodyPartTitle } from './styles';

type SymmaryOfCoverBodyPartProps = {
  title: string;
};

const SummaryOfCoverBodyPart: React.FC<SymmaryOfCoverBodyPartProps> = ({
  title,
  children,
}) => {
  return (
    <div>
      <BodyPartTitle>{title}</BodyPartTitle>
      {children}
    </div>
  );
};

export default SummaryOfCoverBodyPart;
