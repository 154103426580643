import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { ModalProps } from '@rsa-digital/evo-shared-components/components/Modal';
import ComparisonTableWithMultiLevelHeadings from '@rsa-digital/evo-shared-components/components/Table/ComparisonTableWithMultiLevelHeadings';
import { ComparisonDataBlock } from '@rsa-digital/evo-shared-components/components/Table/ComparisonTableWithMultiLevelHeadings/types';
import { ColumnsByBreakpoint } from '@rsa-digital/evo-shared-components/helpers/columns/types';
import React from 'react';
import { unwrapSingleton } from 'helpers/csTypeProcessors';
import { trackModalLinkClick } from 'helpers/eventTracking';
import usePlusPremierComparisonModalContent, { CsComparisonTableBlock } from './content';
import {
  ContentWrapper,
  HeadingContainer,
  ModalHeading,
  ModalSubheading,
  PolicyLinksHeading,
  PolicyLinkWithMargin,
  StyledGridItem,
  StyledModal,
} from './styles';

const PlusPremierComparisonModal: React.FC<ModalProps> = ({ onClose }) => {
  const {
    modal_id,
    modal_heading,
    modal_subheading,
    policy_document_links: {
      plus_heading,
      premier_heading,
      bullet_icon,
      ipid_plus,
      ipid_premier,
      policy_wording,
    },
    table_headings: { plus_cover, premier_cover },
    comparison_rows: tableBodyRows,
  } = usePlusPremierComparisonModalContent();

  const plusPremierComparisonModalHeadingId = 'plus-premier-comparison-modal-heading';

  const icon = unwrapSingleton(bullet_icon)?.icon_code;

  const useComparisonTableBodyRows = (
    comparison_table_body_rows: CsComparisonTableBlock[]
  ): ComparisonDataBlock[] => {
    return comparison_table_body_rows.map((comparison_table_body_row) => ({
      row_heading: comparison_table_body_row.row_heading,
      row_subheading: comparison_table_body_row.row_subheading,
      comparison_row: {
        left_cell_text: comparison_table_body_row.plus_content,
        right_cell_text: comparison_table_body_row.premier_content,
      },
    }));
  };

  const tableContent = useComparisonTableBodyRows(tableBodyRows);

  const comparisonTableContent = {
    table_heading: {
      left_column_heading: plus_cover,
      right_column_heading: premier_cover,
    },
    table_content: tableContent,
  };

  const modalTableColumnWidths: ColumnsByBreakpoint = {
    mobile: 4,
    tabletPortrait: 7,
    tabletLandscape: 8,
    desktop: 8,
  };

  const policyLinksInfo = [
    { ipidLink: ipid_plus, documentsHeading: plus_heading },
    {
      ipidLink: ipid_premier,
      documentsHeading: premier_heading,
    },
  ];

  return (
    <StyledModal
      data-cy="plusPremierComparisonModal"
      id={modal_id}
      onClose={onClose}
      gridItemProps={{ desktop: 10, tabletLandscape: 10 }}
      aria-labelledby={plusPremierComparisonModalHeadingId}>
      <Grid>
        <GridItem {...modalTableColumnWidths}>
          <ModalHeading id={plusPremierComparisonModalHeadingId}>
            {modal_heading}
          </ModalHeading>
          <ModalSubheading>{modal_subheading}</ModalSubheading>
          <ContentWrapper>
            <ComparisonTableWithMultiLevelHeadings data={comparisonTableContent} />
            <Grid>
              {policyLinksInfo.map((policyLinksInfoPerProduct) => (
                <StyledGridItem
                  desktop={modalTableColumnWidths.desktop / policyLinksInfo.length}
                  tabletLandscape={
                    modalTableColumnWidths.tabletLandscape / policyLinksInfo.length
                  }
                  tabletPortrait={
                    modalTableColumnWidths.tabletPortrait / policyLinksInfo.length
                  }>
                  <HeadingContainer>
                    <PolicyLinksHeading>
                      {policyLinksInfoPerProduct?.documentsHeading}
                    </PolicyLinksHeading>
                  </HeadingContainer>
                  <PolicyLinkWithMargin
                    icon={icon}
                    url={policy_wording.href}
                    text={policy_wording.title}
                    openInNewTab
                    onClick={() =>
                      trackModalLinkClick(
                        'Plus and Premier Comparison Modal',
                        policy_wording.title
                      )
                    }
                  />
                  {policyLinksInfoPerProduct?.ipidLink && (
                    <PolicyLinkWithMargin
                      icon={icon}
                      url={policyLinksInfoPerProduct.ipidLink.href}
                      text={policyLinksInfoPerProduct.ipidLink.title}
                      openInNewTab
                      onClick={() =>
                        trackModalLinkClick(
                          'Plus and premier comaparison modal',
                          policyLinksInfoPerProduct.ipidLink.title
                        )
                      }
                    />
                  )}
                </StyledGridItem>
              ))}
            </Grid>
          </ContentWrapper>
        </GridItem>
      </Grid>
    </StyledModal>
  );
};

export default PlusPremierComparisonModal;
