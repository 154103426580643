import {
  hasValue,
  required,
} from '@rsa-digital/evo-shared-components/helpers/forms/rules';
import { ValidationRules } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { graphql, useStaticQuery } from 'gatsby';
import { quotePlaceholders } from 'helpers/placeholders/quotePlaceholders';
import {
  noOpPlaceholderReplacer,
  replacePlaceholdersPlainText,
} from 'helpers/placeholders/replaceCsPlaceholders';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import { CoverDuration, LifetimeOption, QuoteOptions } from 'state/formData/quoteOptions';

type CsQuoteSummaryErrorMessages = {
  csPetQuoteSummaryChooseExcessV2: {
    confirm_excess_options_question: {
      error_messages: {
        not_accepted: string;
        missing: string;
      };
    };
  };
  csPetQuoteSummaryDemandsAndNeedsQuestionsV2: {
    cover_duration: {
      error_messages: {
        missing: string;
      };
    };
    lifetime_option: {
      error_messages: {
        missing: string;
      };
    };
    plus_and_premier_cover_details: {
      error_messages: {
        missing: string;
      };
    };
  };
};

const query = graphql`
  query {
    csPetQuoteSummaryChooseExcessV2 {
      confirm_excess_options_question {
        error_messages {
          not_accepted
          missing
        }
      }
    }
    csPetQuoteSummaryDemandsAndNeedsQuestionsV2 {
      cover_duration {
        error_messages {
          missing
        }
      }
      lifetime_option {
        error_messages {
          missing
        }
      }
      plus_and_premier_cover_details {
        error_messages {
          missing
        }
      }
    }
  }
`;

const useQuoteSummaryRules = (): ValidationRules<QuoteOptions> => {
  const errorMessages = useStaticQuery<CsQuoteSummaryErrorMessages>(query);

  const quote = useCurrentQuote();

  const plainTextPlaceholderReplacer = quote
    ? replacePlaceholdersPlainText(quotePlaceholders, quote, true)
    : noOpPlaceholderReplacer;

  return {
    coverDuration: [
      required(
        errorMessages.csPetQuoteSummaryDemandsAndNeedsQuestionsV2.cover_duration
          .error_messages.missing
      ),
    ],
    lifetimeOption: [
      {
        validityCondition: (value) =>
          quote.quoteOptions?.coverDuration !== CoverDuration.Lifetime || hasValue(value),
        errorMessage: plainTextPlaceholderReplacer(
          errorMessages.csPetQuoteSummaryDemandsAndNeedsQuestionsV2.lifetime_option
            .error_messages.missing
        ),
      },
    ],
    plusCoverFeeLimit: [
      {
        validityCondition: (value) =>
          quote.quoteOptions?.lifetimeOption !== LifetimeOption.Total_Annual_Limit ||
          hasValue(value) ||
          quote.quoteOptions.premierCoverFeeLimit !== undefined,
        errorMessage: plainTextPlaceholderReplacer(
          errorMessages.csPetQuoteSummaryDemandsAndNeedsQuestionsV2
            .plus_and_premier_cover_details.error_messages.missing
        ),
      },
    ],
    premierCoverFeeLimit: [
      {
        validityCondition: (value) =>
          quote.quoteOptions?.lifetimeOption !== LifetimeOption.Total_Annual_Limit ||
          hasValue(value) ||
          quote.quoteOptions.plusCoverFeeLimit !== undefined,
        errorMessage: plainTextPlaceholderReplacer(
          errorMessages.csPetQuoteSummaryDemandsAndNeedsQuestionsV2
            .plus_and_premier_cover_details.error_messages.missing
        ),
      },
    ],
    hasConfirmedExcessOption: [
      required(
        errorMessages.csPetQuoteSummaryChooseExcessV2.confirm_excess_options_question
          .error_messages.missing
      ),
      {
        validityCondition: (value) => value !== false,
        errorMessage:
          errorMessages.csPetQuoteSummaryChooseExcessV2.confirm_excess_options_question
            .error_messages.not_accepted,
      },
    ],
  };
};

export default useQuoteSummaryRules;
