import { Divider } from '@rsa-digital/evo-shared-components/components/Divider/SimpleDivider/styles';
import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { unwrapSingleton } from 'helpers/csTypeProcessors';
import { PageTitle } from 'helpers/eventTracking';
import { CsIcon } from 'types/contentStack';
import { Heading, StyledRichTextWithModal } from './styles';

type AggregatorAgreementsContent = {
  csPetAggregators: {
    confirmation: {
      heading: string;
      bullet_icon: [CsIcon];
      agreements: string;
    };
  };
};

const query = graphql`
  query {
    csPetAggregators {
      confirmation {
        heading
        bullet_icon {
          icon_code
        }
        agreements
      }
    }
  }
`;

const AggregatorAgreements: React.FC<{ pageTitle: PageTitle }> = ({ pageTitle }) => {
  const content = useStaticQuery<AggregatorAgreementsContent>(query).csPetAggregators
    .confirmation;
  const icon = unwrapSingleton(content.bullet_icon)?.icon_code;

  const headingId = 'aggregator-agreements-heading';

  return (
    <Grid alignLeft as="section" aria-labelledby={headingId}>
      <GridItem>
        <Divider aria-hidden />
      </GridItem>
      <GridItem desktop={9} tabletLandscape={9}>
        <Heading id={headingId}>{content.heading}</Heading>
        <StyledRichTextWithModal
          html={content.agreements}
          customBulletIcon={icon}
          pageTitle={pageTitle}
        />
      </GridItem>
    </Grid>
  );
};

export default AggregatorAgreements;
