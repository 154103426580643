import AddButton from '@rsa-digital/evo-shared-components/components/AddButton';
import {
  Grid,
  GridItem,
  GridItemProps,
} from '@rsa-digital/evo-shared-components/components/Grid';
import Icon from '@rsa-digital/evo-shared-components/components/Icon';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import componentProps, {
  ComponentProps,
} from '@rsa-digital/evo-shared-components/helpers/componentProps';
import React, { MouseEventHandler } from 'react';
import { PageTitle } from 'helpers/eventTracking';
import {
  Container,
  IconWrapper,
  RichTextWithMargin,
  RichTextWithTopMargin,
  StyledHeading,
} from './styles';

type BannerWithAddButtonProps = {
  addFormSectionButtonClick?: MouseEventHandler;
  headingText: string;
  bodyText: string;
  buttonText?: string;
  buttonAriaLabel?: string;
  buttonId?: string;
  helpText?: string;
  icon?: string;
  pageTitle: PageTitle;
  gridItemProps: GridItemProps;
} & ComponentProps;

const BannerWithAddButton: React.FC<BannerWithAddButtonProps> = ({
  addFormSectionButtonClick,
  headingText,
  bodyText,
  buttonText,
  buttonAriaLabel,
  buttonId,
  helpText,
  icon,
  pageTitle,
  gridItemProps,
  ...props
}) => {
  return (
    <Container {...componentProps(props)}>
      <Grid alignLeft>
        <GridItem {...gridItemProps}>
          {icon && (
            <IconWrapper>
              <Icon size="large" name={icon} color={colors.core03} />
            </IconWrapper>
          )}
          <StyledHeading>{headingText}</StyledHeading>
        </GridItem>
        <GridItem {...gridItemProps}>
          <RichTextWithMargin html={bodyText} pageTitle={pageTitle} />
        </GridItem>
        {addFormSectionButtonClick && (
          <GridItem {...gridItemProps}>
            <AddButton
              onClick={addFormSectionButtonClick}
              id={buttonId}
              aria-label={buttonAriaLabel}>
              {buttonText}
            </AddButton>
            {helpText && <RichTextWithTopMargin html={helpText} pageTitle={pageTitle} />}
          </GridItem>
        )}
      </Grid>
    </Container>
  );
};

export default BannerWithAddButton;
