import { petType_CAT, petType_DOG } from 'helpers/referenceDataConstants';
import { CurrentQuote } from 'helpers/useCurrentQuote';
import { CsPlaceholders } from './replaceCsPlaceholders';

export type CoverPercentagePlaceholderData = {
  coveredClaimsPercentage: number;
  coverLimit: string;
  quote: CurrentQuote;
};

const getTextFromPetTypes = (quote: CurrentQuote): string => {
  if (quote?.petInfos?.every((pet) => pet.petType === petType_CAT)) {
    return 'Cat';
  }
  if (quote?.petInfos?.every((pet) => pet.petType === petType_DOG)) {
    return 'Dog';
  }
  return '';
};

export const coverPercentagePlaceholders: CsPlaceholders<CoverPercentagePlaceholderData> = {
  coverLimit: {
    getSubstitutionText: (placeholders) => placeholders.coverLimit,
    isPii: false,
  },
  coveredClaimsPercentage: {
    getSubstitutionText: (placeholders) => `${placeholders.coveredClaimsPercentage}%`,
    isPii: false,
  },
  notCoveredClaimsPercentage: {
    getSubstitutionText: (placeholders) =>
      `${100 - placeholders.coveredClaimsPercentage}%`,
    isPii: false,
  },
  petType: {
    getSubstitutionText: (placeholders) => getTextFromPetTypes(placeholders.quote),
    isPii: false,
  },
};
