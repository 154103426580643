import { CurrentQuote } from './useCurrentQuote';

export const getSelectedProductTotalPrice = (
  quote: CurrentQuote,
  isAnnualPayment: boolean
): number | undefined => {
  const selectedProductPrices = quote.price;

  return isAnnualPayment
    ? selectedProductPrices?.annualPrice.total
    : selectedProductPrices?.monthlyPrice.total;
};
