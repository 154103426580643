import { FlexibleWidthDivider } from '@rsa-digital/evo-shared-components/components/Divider';
import { MaxWidthAndSpacingContainer } from '@rsa-digital/evo-shared-components/components/GridContainer/styles';
import Icon from '@rsa-digital/evo-shared-components/components/Icon';
import { ButtonLink } from '@rsa-digital/evo-shared-components/components/Link';
import SimplePanel from '@rsa-digital/evo-shared-components/components/Panel/SimplePanel';
import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import {
  heavyFontWeight,
  regularFontWeight,
  semiBoldFontWeight,
} from '@rsa-digital/evo-shared-components/theme/MarksAndSpencer/fonts';
import styled from 'styled-components';

export const MarginalTitle = styled.p`
  ${fonts.headingXSmall};

  margin: 0;
  padding: ${spacing(2)};

  width: 100%;
  text-align: center;

  background-color: ${colors.core03};
  & {
    color: ${colors.core02};
  }
`;

export const MarginalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PaymentAmount = styled.p`
  ${fonts.headingLarge};

  ${mediaQuery.tabletPortrait`
    ${fonts.headingMedium};
  `};

  & {
    font-weight: ${semiBoldFontWeight};
    color: ${colors.core03};
  }

  margin: ${spacing(3)} 0 0;
`;

export const PaymentSchedule = styled.p`
  ${fonts.paragraph};

  & {
    font-weight: ${heavyFontWeight};
  }

  margin: 0;
`;

export const MonthlyPaymentReminder = styled.p`
  ${fonts.paragraph};
  text-align: left;
  margin: ${spacing(2)} 0 0;
  padding: 0 0;
  color: ${colors.neutral01};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const SwitchPaymentButton = styled(ButtonLink)`
  text-align: left;
  margin: ${spacing(2)} 0 ${spacing(2)};

  ${mediaQuery.tabletPortrait`
    margin-top: ${spacing(1)};
  `}

  &&& {
    ${mediaQuery.tabletLandscape`
      ${fonts.paragraphLarge};
    `}
  }

  &&& {
    font-weight: ${semiBoldFontWeight};
  }
`;

export const PaymentSimplePanel = styled(SimplePanel)`
  text-align: left;

  ${mediaQuery.tabletPortrait`
      width: 74%;
      margin: ${spacing(3)} 0 ${spacing(4)};
      padding: ${spacing(3)};
  `}
`;

export const PayMonthlyPromotion = styled.div`
  margin-bottom: ${spacing(2)};
`;

export const StyledFlexibleWidthDivider = styled(FlexibleWidthDivider)`
  margin-top: ${spacing(2)};
  margin-bottom: ${spacing(2)};

  hr {
    background-color: ${colors.core01};
  }

  ${mediaQuery.tabletPortrait`
      margin-bottom: ${spacing(3)};
  `}
`;

export const PayMonthlyPromoHeadingWrapper = styled.div`
  ${fonts.paragraphLarge}
  & {
    font-weight: ${semiBoldFontWeight};
  }
  display: flex;
  align-items: center;
  height: ${spacing(4)};
  margin-bottom: ${spacing(2)};

  ${Icon} {
    color: ${colors.cardIcon};
    margin-right: ${spacing(2)};
  }

  ${mediaQuery.tabletPortrait`
    margin-bottom: ${spacing(1)};
  `}
`;

export const StyledRichText = styled(RichText)`
  text-align: left;
  ${fonts.paragraphSmall}
`;

export const ParagraphLightWithMarginTop = styled.p`
  ${fonts.paragraph};
  & {
    max-width: 100%;
    text-align: left;
  }
  margin: 0 auto ${spacing(2)};
  padding: 0 ${spacing(2)};
  ${MaxWidthAndSpacingContainer} {
    max-width: 546px;
    margin: 0;
    padding: 0;
    ${fonts.paragraphSmall}
  }
  ${MaxWidthAndSpacingContainer} {
    font-weight: ${regularFontWeight};
  }
  ${mediaQuery.tabletPortrait`
    max-width: 74%;
    padding: 0; 
  `}
`;

export const ParagraphLightWithMargin = styled.p`
  ${fonts.paragraphLarge};
  margin: ${spacing(2)} 0 ${spacing(2)};
  display: flex;
  padding: ${spacing(0)} ${spacing(1)};
  strong {
    font-weight: ${semiBoldFontWeight};
  }
  ${StyledRichText} {
    ${fonts.paragraphLarge};
    margin: 0 0 0 ${spacing(2)};
  }
  ${Icon} {
    position: relative;
    top: ${spacing(0.5)};
    min-width: ${spacing(3)};
  }
`;
