import React from 'react';
import { ExcessBarContainer, GreenExcessBar, GreyExcessBar } from './styles';
import { ExcessSegmentProps } from '../ExcessSegment';
import {
  ExcessAmount,
  ExcessRichText,
  ExcessSegmentContainer,
  ExcessSegmentTextContainer,
} from '../ExcessSegment/styles';

const TotalExcessSegment: React.FC<ExcessSegmentProps> = ({
  excessAmount,
  excessText,
}) => {
  return (
    <ExcessSegmentContainer>
      <ExcessSegmentTextContainer>
        <ExcessAmount>{excessAmount}</ExcessAmount>
        <ExcessRichText html={excessText} />
      </ExcessSegmentTextContainer>
      <ExcessBarContainer>
        <GreenExcessBar />
        <GreyExcessBar />
      </ExcessBarContainer>
    </ExcessSegmentContainer>
  );
};

export default TotalExcessSegment;
