import InfoBox from '@rsa-digital/evo-shared-components/components/InfoBox';
import componentProps, {
  ComponentProps,
} from '@rsa-digital/evo-shared-components/helpers/componentProps';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { appliesToSelection } from 'helpers/productHelpers';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import { CsApplicableProducts, CsInfoBox } from 'types/contentStack';

export type CsPetQuoteSummaryOfYourNeeds = {
  csPetQuoteSummaryOfYourNeedsV2: {
    info_sections: {
      applicable_products: CsApplicableProducts;
      info_box: CsInfoBox;
    }[];
  };
};

const query = graphql`
  query {
    csPetQuoteSummaryOfYourNeedsV2 {
      info_sections {
        applicable_products {
          product_selector
        }
        info_box {
          callout_heading
          callout_body
          body
        }
      }
    }
  }
`;

const InfoBoxSection: React.FC<ComponentProps> = ({ ...rest }) => {
  const quote = useCurrentQuote();

  const {
    csPetQuoteSummaryOfYourNeedsV2: { info_sections },
  } = useStaticQuery<CsPetQuoteSummaryOfYourNeeds>(query);

  const infoSections = info_sections.filter((section) =>
    appliesToSelection(quote.quoteOptions, section.applicable_products)
  );

  return (
    <section>
      {infoSections.map((infoSection) => (
        <InfoBox
          key={infoSection.info_box.body}
          {...componentProps(rest)}
          mainPart={infoSection.info_box.body}
          calloutHeading={infoSection.info_box.callout_heading}
          calloutBody={infoSection.info_box.callout_body}
          data-cy="infoBox"
        />
      ))}
    </section>
  );
};

export default InfoBoxSection;
