import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const ExcessBarContainer = styled.div`
  display: inline-flex:
  flex-direction: row;
  justify-content: space-between;
`;

export const GreenExcessBar = styled.div`
  height: ${spacing(2)};
  width: 47.5%;
  background-color: ${colors.core03};
`;

export const GreyExcessBar = styled.div`
  height: ${spacing(2)};
  width: 47.5%;
  background-color: ${colors.neutral03};
`;
