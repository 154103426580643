import { isPet9YearsOrOlderOnDate } from 'businessLogic/petAge';
import React, { useState } from 'react';
import CustomModal, { getModalId } from 'components/CustomModal';
import { PageTitle, trackModalOpen, trackRichTextLinkClick } from 'helpers/eventTracking';
import { getQuotePlaceholdersWithProductOption } from 'helpers/placeholders/quotePlaceholders';
import { replacePlaceholdersRichText } from 'helpers/placeholders/replaceCsPlaceholders';
import { formatStringInPoundsWithSign } from 'helpers/poundsHelpers';
import { Product } from 'helpers/productHelpers';
import { CurrentQuote, useCurrentQuote } from 'helpers/useCurrentQuote';
import usePricingInfoContent, { getPricingInfoPerProduct } from './content';
import {
  DescriptionRichText,
  DescriptionSingleLine,
  ModalLinkTextWithTopMargin,
  MultipetOverAndUnderNineStatement,
  PetExcess,
  PetsExcesses,
  PricingInfoRow,
} from './styles';

type PricingInfoProps = {
  pageTitle: PageTitle;
  product: Product;
};

const displayMultipetStatement = (currentQuote: CurrentQuote): boolean => {
  const coverStartDate = currentQuote.policyInfo
    ? new Date(currentQuote.policyInfo.coverStartDate)
    : new Date();

  const petDOBs = currentQuote?.petInfos?.map((petDetail) =>
    isPet9YearsOrOlderOnDate(petDetail.dob, coverStartDate)
  );
  if ((currentQuote.petInfos?.length || 0) > 1 && petDOBs?.includes(true)) {
    return true;
  }
  return false;
};

const PricingInfo: React.FC<PricingInfoProps> = ({ pageTitle, product }) => {
  const quote = useCurrentQuote();
  const [modalId, setModalId] = useState<string | null>(null);
  const pricingInfoContent = usePricingInfoContent();

  const replaceProductPlaceholders = replacePlaceholdersRichText(
    getQuotePlaceholdersWithProductOption(product),
    quote
  );

  const pricingInfoPerSelectedProduct = getPricingInfoPerProduct(
    pricingInfoContent,
    product
  );

  return (
    <>
      <PricingInfoRow>
        <DescriptionSingleLine data-cy="summary-of-cover-excess-description-single-line">
          {pricingInfoPerSelectedProduct.excess.description_single_line}
        </DescriptionSingleLine>
        <PetsExcesses>
          {quote.petInfos?.map((petInfo) => (
            <PetExcess data-cy="summary-of-cover-pet-excess">
              {formatStringInPoundsWithSign(petInfo.voluntaryExcessAmount)} for{' '}
              {petInfo.petName}
            </PetExcess>
          ))}
        </PetsExcesses>
        {displayMultipetStatement(quote) && (
          <MultipetOverAndUnderNineStatement>
            {pricingInfoPerSelectedProduct.excess.multipet_over_and_under_nine_statement}
          </MultipetOverAndUnderNineStatement>
        )}
        <DescriptionRichText
          html={pricingInfoPerSelectedProduct.excess.description_rich_text}
          pageTitle={pageTitle}
          data-cy="summary-of-cover-excess-description-rich-text"
        />
      </PricingInfoRow>
      <PricingInfoRow>
        <DescriptionRichText
          html={replaceProductPlaceholders(
            pricingInfoPerSelectedProduct.vet_fees_limit.description_rich_text
          )}
          pageTitle={pageTitle}
          data-cy="summary-of-cover-vet-fees-limit-rich-text"
        />
      </PricingInfoRow>
      <PricingInfoRow>
        <ModalLinkTextWithTopMargin
          onClick={() => {
            trackModalOpen(
              pricingInfoPerSelectedProduct.typical_treatment_costs.modal_link.link_text
            );
            setModalId(
              getModalId(
                pricingInfoPerSelectedProduct.typical_treatment_costs.modal_link.modal
              )
            );
          }}
          data-cy="summary-of-cover-typical-treatment-costs-modal">
          {pricingInfoPerSelectedProduct.typical_treatment_costs.modal_link.link_text}
        </ModalLinkTextWithTopMargin>
      </PricingInfoRow>
      <CustomModal
        modalId={modalId}
        onClose={() => {
          setModalId(null);
        }}
        pageTitle={pageTitle}
        trackRichTextLinkClick={trackRichTextLinkClick(pageTitle, modalId ?? undefined)}
      />
    </>
  );
};

export default PricingInfo;
