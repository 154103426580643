import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import { scrollAndFocusInput } from '@rsa-digital/evo-shared-components/helpers/forms/scrollAndFocusError';
import { FieldFunction } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import BooleanRadioInput from 'components/BooleanRadioInput';
import { PageTitle } from 'helpers/eventTracking';
import { useCurrentQuote, useUpdateQuoteOptions } from 'helpers/useCurrentQuote';
import useQuestionProcessor from 'helpers/useQuestionProcessor';
import { QuoteOptions } from 'state/formData/quoteOptions';
import { CsQuestion } from 'types/contentStack';
import {
  AdditionalQuestionInfoPanel,
  FlexibleWidthDividerWithMargin,
  Heading,
  StyledQuestionField,
  TextAndQuestionWrapper,
} from './styles';

export type CsAdditionalQuestionSection = {
  csPetQuoteSummaryOfYourNeedsV2: {
    additional_question_essential_cover: {
      info_panel: {
        heading: string;
        body: string;
      };
      question_field: CsQuestion;
    };
  };
};

const query = graphql`
  query {
    csPetQuoteSummaryOfYourNeedsV2 {
      additional_question_essential_cover {
        info_panel {
          heading
          body
        }
        question_field {
          main_details {
            question_text
            explanatory_text
            tooltip {
              tooltip_text
              tooltip_open
              tooltip_close
            }
            alert_text
          }
        }
      }
    }
  }
`;

type AdditionalQuestionForEssentialProps = {
  formValidation: {
    getError: FieldFunction<QuoteOptions, string | undefined>;
    showValidation: FieldFunction<QuoteOptions, void>;
  };
};

const AdditionalQuestionForEssential: React.FC<AdditionalQuestionForEssentialProps> = ({
  formValidation: { getError, showValidation },
}) => {
  const {
    csPetQuoteSummaryOfYourNeedsV2: { additional_question_essential_cover },
  } = useStaticQuery<CsAdditionalQuestionSection>(query);

  const quote = useCurrentQuote();

  const updateQuoteOptions = useUpdateQuoteOptions();
  const { processQuestion } = useQuestionProcessor(PageTitle.QuoteSummary);

  const additionalQuestion = processQuestion(
    additional_question_essential_cover.question_field
  );

  const inputValue = quote?.quoteOptions.additionalQuestionEssentialCover;

  return (
    <Grid as="section" alignLeft>
      <GridItem desktop={6} tabletLandscape={8} tabletPortrait={7}>
        <AdditionalQuestionInfoPanel data-cy="additionalQuestionEssentialCover">
          <TextAndQuestionWrapper>
            <Heading>{additional_question_essential_cover.info_panel.heading}</Heading>
            <RichText html={additional_question_essential_cover.info_panel.body} />
            <FlexibleWidthDividerWithMargin />
            <StyledQuestionField
              errorText={getError('additionalQuestionEssentialCover')}
              question={additionalQuestion}>
              <BooleanRadioInput
                id="additionalQuestionEssentialCover"
                value={inputValue}
                onChange={(newValue) => {
                  updateQuoteOptions({
                    additionalQuestionEssentialCover: newValue,
                  });
                  if (!newValue) {
                    scrollAndFocusInput('coverDurationRadioButton');
                  }
                  showValidation('additionalQuestionEssentialCover');
                }}
              />
            </StyledQuestionField>
          </TextAndQuestionWrapper>
        </AdditionalQuestionInfoPanel>
      </GridItem>
    </Grid>
  );
};

export default AdditionalQuestionForEssential;
