import { graphql, useStaticQuery } from 'gatsby';
import { Product } from 'helpers/productHelpers';
import { CsModal } from 'types/contentStack';

export type CsPricingInfoPerProduct = {
  excess: {
    description_single_line: string;
    multipet_over_and_under_nine_statement: string;
    description_rich_text: string;
  };
  vet_fees_limit: {
    modal_link: {
      link_text: string;
      modal: [CsModal] | [];
    };
    description_rich_text: string;
  };
  typical_treatment_costs: {
    modal_link: {
      link_text: string;
      modal: [CsModal] | [];
    };
    banner_text: string;
    description_rich_text: string;
  };
};

export type CsPricingInfo = {
  csPetSummaryOfCover: {
    pricing_info: {
      heading: string;
      essential: CsPricingInfoPerProduct;
      standard: CsPricingInfoPerProduct;
      plus: CsPricingInfoPerProduct;
      premier: CsPricingInfoPerProduct;
    };
  };
};

const query = graphql`
  query {
    csPetSummaryOfCover {
      pricing_info {
        heading
        essential {
          excess {
            description_single_line
            multipet_over_and_under_nine_statement
            description_rich_text
          }
          vet_fees_limit {
            modal_link {
              link_text
              modal {
                modal_id
              }
            }
            description_rich_text
          }
          typical_treatment_costs {
            modal_link {
              link_text
              modal {
                modal_id
              }
            }
            description_rich_text
          }
        }
        standard {
          excess {
            description_single_line
            multipet_over_and_under_nine_statement
            description_rich_text
          }
          vet_fees_limit {
            modal_link {
              link_text
              modal {
                modal_id
              }
            }
            description_rich_text
          }
          typical_treatment_costs {
            modal_link {
              link_text
              modal {
                modal_id
              }
            }
            banner_text
            description_rich_text
          }
        }
        plus {
          excess {
            description_single_line
            multipet_over_and_under_nine_statement
            description_rich_text
          }
          vet_fees_limit {
            modal_link {
              link_text
              modal {
                modal_id
              }
            }
            description_rich_text
          }
          typical_treatment_costs {
            modal_link {
              link_text
              modal {
                modal_id
              }
            }
            banner_text
            description_rich_text
          }
        }
        premier {
          excess {
            description_single_line
            multipet_over_and_under_nine_statement
            description_rich_text
          }
          vet_fees_limit {
            modal_link {
              link_text
              modal {
                modal_id
              }
            }
            description_rich_text
          }
          typical_treatment_costs {
            modal_link {
              link_text
              modal {
                modal_id
              }
            }
            banner_text
            description_rich_text
          }
        }
      }
    }
  }
`;

const usePricingInfoContent = (): CsPricingInfo => useStaticQuery<CsPricingInfo>(query);

export const getPricingInfoPerProduct = (
  pricingInfo: CsPricingInfo,
  product: Product
): CsPricingInfoPerProduct => {
  switch (product) {
    case Product.Essential:
      return pricingInfo.csPetSummaryOfCover.pricing_info.essential;
    case Product.Standard:
      return pricingInfo.csPetSummaryOfCover.pricing_info.standard;
    case Product.Plus:
      return pricingInfo.csPetSummaryOfCover.pricing_info.plus;
    case Product.Premier:
      return pricingInfo.csPetSummaryOfCover.pricing_info.premier;
    default:
      throw new Error('Invalid product type');
  }
};

export default usePricingInfoContent;
