import { scrollToElement } from '@rsa-digital/evo-shared-components/helpers/scroll';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { quotePlaceholders } from 'helpers/placeholders/quotePlaceholders';
import {
  noOpPlaceholderReplacer,
  replacePlaceholdersPlainText,
} from 'helpers/placeholders/replaceCsPlaceholders';
import { getProductFromQuoteOptions, Product } from 'helpers/productHelpers';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import { StyledLinkText, StyledProductInfo } from './styles';

export type PriceBannerTextProps = {
  csPetQuoteSummaryV2: {
    price_banner: {
      monthly_label: string;
      annual_label: string;
      cover_type: string;
      excess_amount: string;
      vet_fees_limit: string;
      vet_fees_limit_others: string;
      view_your_excesses: string;
    };
  };
};

export const query = graphql`
  query {
    csPetQuoteSummaryV2 {
      price_banner {
        monthly_label
        annual_label
        cover_type
        excess_amount
        vet_fees_limit
        vet_fees_limit_others
        view_your_excesses
      }
    }
  }
`;

export const PriceBannerText: React.FC = () => {
  const { price_banner } = useStaticQuery<PriceBannerTextProps>(
    query
  ).csPetQuoteSummaryV2;

  const quote = useCurrentQuote();
  const product = getProductFromQuoteOptions(quote.quoteOptions);
  const productIsStandard = product === Product.Standard;
  const numberOfPetsInQuote = quote.petInfos?.length || 0;

  const plainTextPlaceholderReplacer = quote
    ? replacePlaceholdersPlainText(quotePlaceholders, quote, true)
    : noOpPlaceholderReplacer;

  return (
    <StyledProductInfo>
      <p>
        <strong>{plainTextPlaceholderReplacer(price_banner.cover_type)}</strong>
      </p>
      <p>
        {productIsStandard
          ? plainTextPlaceholderReplacer(price_banner.vet_fees_limit)
          : plainTextPlaceholderReplacer(price_banner.vet_fees_limit_others)}
      </p>
      <p>
        {numberOfPetsInQuote > 1 ? (
          <StyledLinkText onClick={() => scrollToElement('excess-section', 10)}>
            {price_banner.view_your_excesses}
          </StyledLinkText>
        ) : (
          plainTextPlaceholderReplacer(price_banner.excess_amount)
        )}
      </p>
    </StyledProductInfo>
  );
};
