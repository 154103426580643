import Icon from '@rsa-digital/evo-shared-components/components/Icon';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import { scrollAndFocusInput } from '@rsa-digital/evo-shared-components/helpers/forms/scrollAndFocusError';
import { FieldFunction } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import React from 'react';
import BooleanRadioInput from 'components/BooleanRadioInput';
import { lifetimeOptionsRadioButtonId } from 'components/QuoteSummary/LifetimeOptionQuestion';
import { unwrapSingleton } from 'helpers/csTypeProcessors';
import { PageTitle } from 'helpers/eventTracking';
import { Product } from 'helpers/productHelpers';
import { useCurrentQuote, useUpdateQuoteOptions } from 'helpers/useCurrentQuote';
import useQuestionProcessor from 'helpers/useQuestionProcessor';
import { QuoteOptions } from 'state/formData/quoteOptions';
import {
  getAdditionalQuestionSectionContentPerProduct,
  useAdditionalQuestionSectionContent,
} from './content';
import { isStandardCover } from './helpers';
import {
  FlexContainer,
  FlexibleWidthDividerWithMargin,
  IconWrapper,
  LargeRichText,
  StyledQuestionField,
} from './styles';

type AdditionalQuestionProps = {
  formValidation: {
    getError: FieldFunction<QuoteOptions, string | undefined>;
  };
  pageTitle: PageTitle;
  product: Product;
};

const AdditionalQuestionSection: React.FC<AdditionalQuestionProps> = ({
  formValidation: { getError },
  pageTitle,
  product,
}) => {
  const additionalQuestionSectionContent = useAdditionalQuestionSectionContent();
  const additionalQuestionContentPerProduct = getAdditionalQuestionSectionContentPerProduct(
    additionalQuestionSectionContent,
    product
  );

  const quote = useCurrentQuote();
  const updateQuoteOptions = useUpdateQuoteOptions();
  const { processQuestion } = useQuestionProcessor(PageTitle.QuoteSummary);

  const additionalQuestion = processQuestion(
    additionalQuestionContentPerProduct.question_field
  );

  const getAdditionalQuestionId = (quoteOptions: QuoteOptions): keyof QuoteOptions => {
    if (isStandardCover(quoteOptions)) {
      return 'additionalQuestionStandardCover';
    }
    return 'additionalQuestionPlus2kCover';
  };

  const getAdditionalQuestionInputValue = (): boolean | undefined => {
    switch (product) {
      case Product.Standard:
        return quote?.quoteOptions?.additionalQuestionStandardCover;
      case Product.Plus:
        return quote?.quoteOptions?.additionalQuestionPlus2kCover;
      default:
        return undefined;
    }
  };

  const inputValue = getAdditionalQuestionInputValue();

  const icon = unwrapSingleton(additionalQuestionContentPerProduct.icon)?.icon_code;

  return (
    <>
      <FlexContainer>
        {icon && (
          <IconWrapper>
            <Icon size="large" name={icon} color={colors.core03} />
          </IconWrapper>
        )}
        <div>
          <LargeRichText
            html={additionalQuestionContentPerProduct.info_panel}
            pageTitle={pageTitle}
          />
          <FlexibleWidthDividerWithMargin />
          <StyledQuestionField
            errorText={getError(getAdditionalQuestionId(quote.quoteOptions))}
            question={additionalQuestion}>
            <BooleanRadioInput
              id={getAdditionalQuestionId(quote.quoteOptions)}
              value={inputValue}
              onChange={(newValue) => {
                updateQuoteOptions({
                  [getAdditionalQuestionId(quote.quoteOptions)]: newValue,
                });
                if (!newValue) {
                  scrollAndFocusInput(lifetimeOptionsRadioButtonId);
                }
              }}
              yesLabel={additionalQuestionContentPerProduct.yes_button_text}
              noLabel={additionalQuestionContentPerProduct.no_button_text}
            />
          </StyledQuestionField>
        </div>
      </FlexContainer>
    </>
  );
};

export default AdditionalQuestionSection;
