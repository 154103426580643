import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import { heavyFontWeight } from '@rsa-digital/evo-shared-components/theme/MarksAndSpencer/fonts';
import styled from 'styled-components';
import DiscountPanel from 'components/DiscountPanel';
import { IconWrapper } from 'components/DiscountPanel/styles';

export const SummaryOfCoverContainer = styled.div`
  margin: ${spacing(4)} 0 ${spacing(2)};
  border-left: solid 1px ${colors.neutral03};
  border-right: solid 1px ${colors.neutral03};
  border-bottom: solid 8px ${colors.core03};
`;

// Tech-Debt - Check effect of spelling mistake below
// Temporarily commented out to remove warning
export const SelectedCoverHeading = styled.h2`
  ${fonts.headingMedium};

  margin-top: ${spacing(3)};
  /* maegin-bottom: ${spacing(3)}; */

  ${mediaQuery.tabletPortrait`
    margin-bottom: ${spacing(5)};
  `}
`;

export const StyledDiscountPanel = styled(DiscountPanel)`
  margin-top: 0;
  display: block;
  ${IconWrapper} {
    float: left;
  }
  ${mediaQuery.tabletPortrait`
         display: flex;
         
  `}
  h3 {
    ${fonts.headingSmall};
  }
  h3 {
    font-weight: 600;
    margin: ${spacing(1)} 0 ${spacing(2)} 0;
  }
  h4 {
    font-weight: ${heavyFontWeight};
    margin: 0;
  }
  p {
    margin-top: ${spacing(1)};
  }
  p + p {
    margin-top: ${spacing(1)};
  }
`;
