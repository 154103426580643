import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { useValidQuoteSummaryOffers } from 'helpers/discountHelpers';
import { PageTitle } from 'helpers/eventTracking';
import { getPetTypesBeingInsured } from 'helpers/getPetTypesBeingInsured';
import { getQuotePlaceholdersWithProductOption } from 'helpers/placeholders/quotePlaceholders';
import { replacePlaceholdersPlainText } from 'helpers/placeholders/replaceCsPlaceholders';
import { Product } from 'helpers/productHelpers';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import { CoverDuration, LifetimeOption, QuoteOptions } from 'state/formData/quoteOptions';
import PricingInfo from './PricingInfo';
import SummaryOfCoverBodyPart from './SummaryOfCoverBodyPart';
import SummaryOfCoverMarginal from './SummaryOfCoverMarginal';
import {
  SelectedCoverHeading,
  StyledDiscountPanel,
  SummaryOfCoverContainer,
} from './styles';
import ProductFeaturesTable from '../ProductFeaturesTable';
import useProductFeatureTableContent from '../ProductFeaturesTable/content';

type CsSummaryOfCover = {
  csPetSummaryOfCover: {
    selected_cover_heading: string;
    pricing_info: {
      heading: string;
    };
    features_info: {
      included_features_heading: string;
      not_included_features_heading: string;
    };
  };
};

const query = graphql`
  query {
    csPetSummaryOfCover {
      selected_cover_heading
      pricing_info {
        heading
      }
      features_info {
        included_features_heading
        not_included_features_heading
      }
    }
  }
`;

type SummaryOfCoverProps = {
  pageTitle: PageTitle;
  product: Product;
  summaryOfCoverRef: React.RefObject<HTMLDivElement>;
};

const SummaryOfCover: React.FC<SummaryOfCoverProps> = ({
  pageTitle,
  product,
  summaryOfCoverRef: tableRef,
}) => {
  const {
    csPetSummaryOfCover: { selected_cover_heading, pricing_info, features_info },
  } = useStaticQuery<CsSummaryOfCover>(query);

  const {
    csPetSummaryOfCover: {
      features_info: { product_feature },
    },
  } = useProductFeatureTableContent();
  const quote = useCurrentQuote();
  const insuredPetTypes = getPetTypesBeingInsured(quote?.petInfos || []);

  const displayedFeaturesForProduct = product_feature.filter(
    (feature) =>
      feature.display_on_products_selector.product_selector.includes(product) &&
      (feature.applicable_pets.includes('All cases') ||
        feature.applicable_pets.includes(insuredPetTypes))
  );
  const includedFeaturesForProduct = displayedFeaturesForProduct.filter((feature) =>
    feature.included_with_products_selector.product_selector.includes(product)
  );
  const notIncludedFeaturesForProduct = displayedFeaturesForProduct.filter(
    (feature) =>
      !feature.included_with_products_selector.product_selector.includes(product)
  );

  const replaceProductPlaceholders = replacePlaceholdersPlainText(
    getQuotePlaceholdersWithProductOption(product),
    quote
  );

  const displaySelectedCoverHeading = (currentQuoteOptions: QuoteOptions): boolean => {
    if (
      currentQuoteOptions.coverDuration === CoverDuration.Time_Limited ||
      currentQuoteOptions.lifetimeOption ===
        LifetimeOption.Annual_Accident_Or_Illness_Limit
    ) {
      return true;
    }
    return false;
  };
  const quoteSummaryOfferPanel = useValidQuoteSummaryOffers();
  return (
    <div ref={tableRef} data-cy="summary-of-cover">
      {displaySelectedCoverHeading(quote.quoteOptions) && (
        <SelectedCoverHeading>
          {replaceProductPlaceholders(selected_cover_heading)}
        </SelectedCoverHeading>
      )}
      <SummaryOfCoverContainer>
        <SummaryOfCoverMarginal product={product} />

        {quoteSummaryOfferPanel && (
          <StyledDiscountPanel
            data-cy="quoteSummaryOfferPanel"
            offerPanel={quoteSummaryOfferPanel}
            pageTitle={PageTitle.QuoteSummary}
          />
        )}
        <SummaryOfCoverBodyPart title={replaceProductPlaceholders(pricing_info.heading)}>
          <PricingInfo pageTitle={pageTitle} product={product} />
        </SummaryOfCoverBodyPart>
        <SummaryOfCoverBodyPart
          title={replaceProductPlaceholders(features_info.included_features_heading)}>
          <ProductFeaturesTable
            pageTitle={pageTitle}
            product={product}
            features={includedFeaturesForProduct}
            data-cy="included-product-features-table"
          />
        </SummaryOfCoverBodyPart>
        {notIncludedFeaturesForProduct.length > 0 && (
          <SummaryOfCoverBodyPart
            title={replaceProductPlaceholders(
              features_info.not_included_features_heading
            )}>
            <ProductFeaturesTable
              pageTitle={pageTitle}
              product={product}
              features={notIncludedFeaturesForProduct}
              columnsPerRow={2}
              data-cy="not-included-product-features-table"
            />
          </SummaryOfCoverBodyPart>
        )}
        <SummaryOfCoverMarginal product={product} />
      </SummaryOfCoverContainer>
    </div>
  );
};
export default SummaryOfCover;
