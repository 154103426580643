import FlexibleWidthDivider from '@rsa-digital/evo-shared-components/components/Divider/FlexibleWidthDivider';
import { TooltipBody } from '@rsa-digital/evo-shared-components/components/Form/Field/FieldInfo/Tooltip/styles';
import { InfoWrapper } from '@rsa-digital/evo-shared-components/components/Form/Field/FieldInfo/styles';
import { GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { ButtonLink } from '@rsa-digital/evo-shared-components/components/Link';
import { InfoPanel } from '@rsa-digital/evo-shared-components/components/Panel/StatusPanel';
import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import { semiBoldFontWeight } from '@rsa-digital/evo-shared-components/theme/MarksAndSpencer/fonts';
import styled from 'styled-components';
import ConfirmationBox from 'components/ConfirmationBox';
import DiscountPanel from 'components/DiscountPanel';
import QuestionField from 'components/QuestionField';
import RichTextLarge from 'components/RichTextLarge';
import BannerWithAddButton from 'forms/BannerWithAddButton';
import SectionHeading from 'forms/SectionHeading';

export const StyledParagraph = styled.p`
  ${fonts.paragraph};
  margin-top: ${spacing(4)};
  margin-bottom: ${spacing(4)};

  ${mediaQuery.tabletPortrait`
    margin-top: ${spacing(4)};
  `}

  ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(6)};

    ${fonts.paragraphLarge};
  `}
`;

export const ParagraphWithSmallMargins = styled.p`
  ${fonts.paragraphLarge};
  margin: ${spacing(2)} 0;

  ${mediaQuery.tabletPortrait`
    margin-bottom: ${spacing(4)};
  `}

  ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(3)};
  `}
`;

export const StyledGridItem = styled(GridItem)`
  margin-left: 0;
  margin-bottom: ${spacing(4)};

  ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(6)};
  `}
`;

export const CoverSummaryHeading = styled.h3`
  ${fonts.headingMedium};
  margin-bottom: ${spacing(3)};

  ${mediaQuery.tabletLandscape`
    margin-bottom: ${spacing(4)};
  `}
`;

export const PriceBannerSpace = styled.div`
  height: ${spacing(12)};

  ${mediaQuery.tabletPortrait`
    height: ${spacing(8)};
  `}
`;

export const ConfirmationBoxWithMargin = styled(ConfirmationBox)`
  margin: ${spacing(-1)} 0 ${spacing(3)};

  ${mediaQuery.tabletPortrait`
    margin: ${spacing(-2)} 0 ${spacing(4)};
  `}
`;

export const RichTextLargeWithMargins = styled(RichTextLarge)`
  margin: ${spacing(2)} 0;
  ${mediaQuery.tabletPortrait`
    margin: ${spacing(3)} 0;
  `}

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const InfoPanelWithMargins = styled(InfoPanel)`
  padding: ${spacing(2)} ${spacing(2)} ${spacing(3)};

  svg {
    fill: ${colors.core03};
  }

  ${mediaQuery.tabletPortrait`
    padding-bottom: ${spacing(2)};
  `}

  ${mediaQuery.tabletLandscape`
    padding: ${spacing(3)};
  `}
`;

export const QuestionFieldWithMargin = styled(QuestionField)`
  margin-bottom: ${spacing(1)};
  ${InfoWrapper} {
    margin-bottom: ${spacing(1)};
    ${mediaQuery.tabletLandscape`
      margin-bottom: ${spacing(1)};
    `}

    ${TooltipBody} {
      p:nth-child(even) {
        margin-top: ${spacing(1)};
      }
      p:nth-child(3) {
        margin-top: ${spacing(3)};
      }
    }
  }
`;

export const CoverTableIntro = styled.p`
  ${fonts.headingMedium};

  margin: 0 0 ${spacing(4)};
`;

export const StyledBannerWithAddButton = styled(BannerWithAddButton)`
  margin-top: ${spacing(4)};

  ${mediaQuery.tabletPortrait`
    margin-top: ${spacing(3)};
  `}
`;

export const SetYourExcessSectionText = styled(RichTextLarge)`
  ${RichText} {
    p {
      ${fonts.paragraphLarge};

      ${mediaQuery.tabletPortrait`
        ${fonts.paragraph};   
      `}

      ${mediaQuery.tabletLandscape`
        ${fonts.paragraphLarge};
    `}
    }
  }
`;

export const FlexibleWidthDividerWithMarginTop = styled(FlexibleWidthDivider)`
  margin-top: ${spacing(4)};

  ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(6)};
  `}
`;

export const ComparisonModalButtonLink = styled(ButtonLink)`
  &&& {
    ${fonts.paragraphLarge};
  }

  &&&& {
    font-weight: ${semiBoldFontWeight};
  }
`;

export const PlusOrPremierCoverHeading = styled.h2`
  ${fonts.headingMedium};

  margin-top: ${spacing(3)};
  margin-bottom: ${spacing(2)};
  ${mediaQuery.tabletLandscape`
    margin-bottom: ${spacing(4)};
  `}
`;

export const OfferOverviewPanel = styled(DiscountPanel)`
  margin-top: ${spacing(1)};
  margin-bottom: ${spacing(4)};
`;

export const LifetimeOptionDiscountPanel = styled(DiscountPanel)`
  margin-top: 0;
`;

export const StyledSectionHeading = styled(SectionHeading)`
  margin-top: ${spacing(2)};
`;
