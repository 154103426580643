import FlexibleWidthDivider from '@rsa-digital/evo-shared-components/components/Divider/FlexibleWidthDivider';
import { Wrapper } from '@rsa-digital/evo-shared-components/components/Form/RadioInput/styles';
import { GridItemWrapper } from '@rsa-digital/evo-shared-components/components/Grid/GridItem/styles';
import { IconWrapper } from '@rsa-digital/evo-shared-components/components/Icon/styles';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import { StyledRadioInput } from 'components/BooleanRadioInput/styles';
import QuestionField from 'components/QuestionField';
import { InfoPanel } from 'components/StatusPanel';

export const FlexibleWidthDividerWithMargin = styled(FlexibleWidthDivider)`
  margin: ${spacing(3)} 0 ${spacing(3)} ${spacing(-1)};

  ${mediaQuery.tabletLandscape`
    margin-left: ${spacing(-2)};
  `}
`;

export const AdditionalQuestionInfoPanel = styled(InfoPanel)`
  &&& {
    margin: ${spacing(4)} 0 0;
    padding: ${spacing(3)} ${spacing(3)} ${spacing(3)} ${spacing(3)};
    border: 1px solid ${colors.neutral01};

    ${IconWrapper} {
      height: ${spacing(4)};
      width: ${spacing(4)};
    }

    p {
      ${fonts.paragraphLarge};
    }

    p + p {
      margin-top: ${spacing(1)};
    }
  }
`;

export const StyledQuestionField = styled(QuestionField)`
  margin-bottom: 0;

  ${GridItemWrapper} {
    width: calc(100% - ${spacing(2)});

    ${mediaQuery.tabletPortrait`
      width: calc(100% - ${spacing(3)});
    `}

    ${mediaQuery.tabletLandscape`
      width: calc(100% - ${spacing(5)});
    `}
  }

  ${StyledRadioInput} {
    flex-direction: column;
    gap: ${spacing(2)};

    label:last-of-type {
      margin-left: 0;
    }

    ${mediaQuery.tabletPortrait`
      flex-direction: row;
  `}
  }
  ${Wrapper} {
    display: flex;
    flex-direction: column;
    ${mediaQuery.tabletPortrait`
    flex-direction: row;
  `}
  }
`;

export const TextAndQuestionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Heading = styled.h3`
  margin: 0 0 ${spacing(2)};

  ${fonts.headingXSmall}
`;
