import { Option } from '@rsa-digital/evo-shared-components/components/Form/RadioInput';
import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { FieldFunction } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { QuoteResponsePetInfo } from 'apiHelpers/quote/quoteResponse';
import { QuoteUpdaters } from 'apiHelpers/quote/useUpdateQuoteCover';
import { AxiosError } from 'axios';
import { isPet9YearsOrOlderOnDate } from 'businessLogic/petAge';
import { graphql, useStaticQuery } from 'gatsby';
import { trimEnd } from 'lodash';
import React, { useEffect, useState } from 'react';
import BooleanRadioInput from 'components/BooleanRadioInput';
import { VoluntaryExcessAmount } from 'helpers/businessConstants';
import { unwrapSingleton } from 'helpers/csTypeProcessors';
import useDefaultErrorHandling from 'helpers/errorHandling';
import { PageTitle, trackExcessSelection } from 'helpers/eventTracking';
import { petNameReplacer } from 'helpers/placeholders/dynamicPetNameHelpers';
import { quotePlaceholders } from 'helpers/placeholders/quotePlaceholders';
import {
  noOpPlaceholderReplacer,
  replacePlaceholdersPlainText,
  replacePlaceholdersQuestion,
  replacePlaceholdersRichText,
} from 'helpers/placeholders/replaceCsPlaceholders';
import { formatStringInPoundsWithSign } from 'helpers/poundsHelpers';
import { getProductFromQuoteOptions, Product } from 'helpers/productHelpers';
import { petType_CAT, petType_DOG } from 'helpers/referenceDataConstants';
import { useCurrentQuote, useUpdateQuoteOptions } from 'helpers/useCurrentQuote';
import useQuestionProcessor from 'helpers/useQuestionProcessor';
import { QuoteOptions } from 'state/formData/quoteOptions';
import { CsIcon, CsQuestion } from 'types/contentStack';
import PetsOverNineExcessPanel from './PetsOverNineExcessPanel';
import {
  ExcessSectionWrapper,
  FlexibleWidthDividerWithTopBottomMargin,
  LargeRichTextWithModal,
  PetHeading,
  PetsOverNineRichText,
  QuestionFieldWithMargin,
  StyledQuestionField,
  StyledSegmentedSelector,
} from './styles';
import { getTotalAge9ExcessAmount } from '../../../helpers/excessAmountHelper';
import { petPlaceholders } from '../../../helpers/placeholders/petPlaceholders';
import { Question } from '../../../types/forms';

type CsChooseExcessSection = {
  csPetQuoteSummaryChooseExcessV2: {
    choose_excess_pet_heading: string;
    choose_excess_question: CsQuestion & {
      replay_message: {
        essential: string;
        standard: string;
        plus: string;
        premier: string;
      };
    };
    pet_over_nine_choose_excess_question: CsQuestion & {
      pet_over_nine_before_excess_question: string;
      replay_message: {
        essential: string;
        standard: string;
        plus: string;
        premier: string;
      };
    };
    product_specific_text: {
      essential: string;
      standard: string;
      plus: string;
      premier: string;
    };
    pet_specific_text: {
      dog_only: string;
      cat_only: string;
      dog_and_cat: string;
    };
    pets_over_nine: {
      heading_and_intro_text: string;
      example_excess_panel: {
        panel_heading: string;
        multipet_panel_heading: string;
        excess_information_text_by_product: {
          essential: string;
          standard: string;
          plus: string;
          premier: string;
        };
      };
    };
    excess_section_icon: [CsIcon];
    confirm_excess_options_question: CsQuestion;
  };
};

const query = graphql`
  query {
    csPetQuoteSummaryChooseExcessV2 {
      choose_excess_pet_heading
      choose_excess_question {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
        }
        replay_message {
          essential
          standard
          plus
          premier
        }
      }
      pet_over_nine_choose_excess_question {
        pet_over_nine_before_excess_question
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
        }
        replay_message {
          essential
          standard
          plus
          premier
        }
      }
      product_specific_text {
        essential
        standard
        plus
        premier
      }
      pet_specific_text {
        dog_only
        cat_only
        dog_and_cat
      }
      pets_over_nine {
        heading_and_intro_text
        example_excess_panel {
          panel_heading
          multipet_panel_heading
          excess_information_text_by_product {
            essential
            standard
            plus
            premier
          }
        }
      }
      excess_section_icon {
        icon_code
      }
      confirm_excess_options_question {
        error_messages {
          not_accepted
          missing
        }
        main_details {
          alert_text
          explanatory_text
          question_text
          tooltip {
            tooltip_close
            tooltip_open
            tooltip_text
          }
        }
      }
    }
  }
`;

type ChooseExcessProps = {
  quoteUpdaters: QuoteUpdaters;
  pageTitle: PageTitle;
  formValidation: {
    getError: FieldFunction<QuoteOptions, string | undefined>;
    showValidation: FieldFunction<QuoteOptions, void>;
  };
};

const ChooseExcessSection: React.FC<ChooseExcessProps> = ({
  quoteUpdaters,
  pageTitle,
  formValidation,
}) => {
  const csContent = useStaticQuery<CsChooseExcessSection>(query)
    .csPetQuoteSummaryChooseExcessV2;

  const quote = useCurrentQuote();
  const updateQuoteOptions = useUpdateQuoteOptions();
  const { getError, showValidation } = formValidation;

  const { processQuestion } = useQuestionProcessor(PageTitle.QuoteSummary);

  const plainTextPlaceholderReplacer = quote
    ? replacePlaceholdersPlainText(quotePlaceholders, quote, true)
    : noOpPlaceholderReplacer;

  const substituteQuoteQuestionPlaceholders = replacePlaceholdersQuestion(
    plainTextPlaceholderReplacer
  );

  const confirmOptionsQuestion = substituteQuoteQuestionPlaceholders(
    processQuestion(csContent.confirm_excess_options_question)
  );

  const coverStartDate = quote.policyInfo
    ? new Date(quote.policyInfo.coverStartDate)
    : new Date();

  const getExcessQuestion = (petInfo: QuoteResponsePetInfo): Question =>
    isPet9YearsOrOlderOnDate(petInfo.dob, coverStartDate)
      ? processQuestion(csContent.pet_over_nine_choose_excess_question)
      : processQuestion(csContent.choose_excess_question);

  // We are tracking this internal state even if it's on the redux stored quote so that the state can update instantly for the screenreader users
  const [voluntaryExcessAmounts, setVoluntaryExcessAmounts] = useState<
    VoluntaryExcessAmount[] | undefined
  >();

  const substituteExcessAmountPetPlaceholders = (
    pet: QuoteResponsePetInfo | null,
    text: string
  ): string =>
    pet && text ? replacePlaceholdersRichText(petPlaceholders, pet)(text) : '';

  useEffect(() => {
    setVoluntaryExcessAmounts(
      quote.petInfos?.map((petInfo) => petInfo.voluntaryExcessAmount)
    );
  }, [quote]);

  const defaultErrorHandling = useDefaultErrorHandling();

  const getProductSpecificText = (textByProduct: {
    essential: string;
    standard: string;
    plus: string;
    premier: string;
  }): string => {
    switch (getProductFromQuoteOptions(quote.quoteOptions)) {
      case Product.Essential:
        return textByProduct.essential;
      case Product.Standard:
        return textByProduct.standard;
      case Product.Plus:
        return textByProduct.plus;
      case Product.Premier:
        return textByProduct.premier;
      default:
        return '';
    }
  };

  const getExcessReplayText = (petInfo: QuoteResponsePetInfo): string => {
    const excessQuestion = isPet9YearsOrOlderOnDate(petInfo.dob, coverStartDate)
      ? csContent.pet_over_nine_choose_excess_question
      : csContent.choose_excess_question;
    const productSpecificText = getProductSpecificText(excessQuestion.replay_message);
    return substituteExcessAmountPetPlaceholders(petInfo, productSpecificText);
  };

  const getDogAndCatText = (): string => {
    const quoteHasDog = quote.petInfos?.some((pet) => pet.petType === petType_DOG);
    const quoteHasCat = quote.petInfos?.some((pet) => pet.petType === petType_CAT);
    if (quoteHasDog && quoteHasCat) {
      return csContent.pet_specific_text.dog_and_cat;
    }
    return '';
  };

  const getPetSpecificText = (petInfo: QuoteResponsePetInfo | null): string => {
    return petInfo?.petType === petType_CAT
      ? csContent.pet_specific_text.cat_only
      : csContent.pet_specific_text.dog_only;
  };

  const allExcessAmountsEqual = (
    chosenExcessAmounts: VoluntaryExcessAmount[] | undefined
  ): boolean =>
    !!chosenExcessAmounts?.every(
      (excessAmount) => excessAmount === chosenExcessAmounts[0]
    );

  const isMultipetQuote = quote.petInfos && quote.petInfos.length > 1;

  const showMultipetSharedPetsOverNinePanel =
    allExcessAmountsEqual(voluntaryExcessAmounts) &&
    isMultipetQuote &&
    quote.petInfos?.every((pet) => !isPet9YearsOrOlderOnDate(pet.dob, coverStartDate));

  // For all quotes where there is one excess example panel, we should include the 'confirm excess' question in the panel
  // These quotes include:
  // Single pet quotes where pet is under 9
  // Multi-pet quotes where all pets are under 9 and all excesses are equal
  const showEmphasizedQuestions =
    allExcessAmountsEqual(voluntaryExcessAmounts) &&
    quote.petInfos?.every((pet) => !isPet9YearsOrOlderOnDate(pet.dob, coverStartDate));

  const getExcessOptionsForPet = (pet: QuoteResponsePetInfo): Option[] =>
    isPet9YearsOrOlderOnDate(pet.dob, coverStartDate)
      ? [
          { name: '£150', value: VoluntaryExcessAmount.Excess_150 },
          { name: '£200', value: VoluntaryExcessAmount.Excess_200 },
          { name: '£225', value: VoluntaryExcessAmount.Excess_225 },
          { name: '£250', value: VoluntaryExcessAmount.Excess_250 },
        ]
      : [
          { name: '£50', value: VoluntaryExcessAmount.Excess_50 },
          { name: '£100', value: VoluntaryExcessAmount.Excess_100 },
          { name: '£125', value: VoluntaryExcessAmount.Excess_125 },
          { name: '£150', value: VoluntaryExcessAmount.Excess_150 },
        ];

  const confirmExcessQuestion = (
    <Grid alignLeft>
      <GridItem desktop={8} tabletLandscape={8} tabletPortrait={8}>
        <StyledQuestionField
          gridItemProps={{
            desktop: 8,
            tabletLandscape: 8,
            tabletPortrait: 8,
          }}
          addTopPadding={!showEmphasizedQuestions}
          removeBottomMargin={showEmphasizedQuestions}
          question={confirmOptionsQuestion}
          errorText={getError('hasConfirmedExcessOption')}>
          <BooleanRadioInput
            id="hasConfirmedExcessOption"
            value={quote.quoteOptions.hasConfirmedExcessOption}
            onChange={(hasConfirmedExcessOption) => {
              updateQuoteOptions({ hasConfirmedExcessOption });
              showValidation('hasConfirmedExcessOption');
            }}
          />
        </StyledQuestionField>
      </GridItem>
    </Grid>
  );

  const confirmExcessIcon = unwrapSingleton(csContent.excess_section_icon)?.icon_code;

  return (
    <ExcessSectionWrapper>
      {quote.petInfos?.map((pet, index) => {
        const substituteName = replacePlaceholdersQuestion((csString: string) =>
          petNameReplacer(trimEnd(pet.petName), csString)
        );
        return (
          <>
            <PetHeading>
              {petNameReplacer(trimEnd(pet.petName), csContent.choose_excess_pet_heading)}
            </PetHeading>
            {isPet9YearsOrOlderOnDate(pet.dob, coverStartDate) && (
              <PetsOverNineRichText
                html={petNameReplacer(
                  trimEnd(pet.petName),
                  csContent.pet_over_nine_choose_excess_question
                    .pet_over_nine_before_excess_question
                )}
                pageTitle={pageTitle}
              />
            )}
            <QuestionFieldWithMargin
              question={substituteName(getExcessQuestion(pet))}
              // Index is the unique identifier for pet info
              // eslint-disable-next-line react/no-array-index-key
              key={`petExcess[${index}]`}>
              <StyledSegmentedSelector
                id={`petExcess[${index}]`}
                shownOptions={4}
                value={voluntaryExcessAmounts?.[index]}
                options={getExcessOptionsForPet(pet)}
                onChange={async (e) => {
                  if (!quoteUpdaters) {
                    console.error('No quote loaded - could not update quote');
                    return;
                  }
                  const voluntaryExcessAmountsUpdated = voluntaryExcessAmounts
                    ? [...voluntaryExcessAmounts]
                    : [];
                  voluntaryExcessAmountsUpdated[index] = e.target
                    .value as VoluntaryExcessAmount;

                  setVoluntaryExcessAmounts(voluntaryExcessAmountsUpdated);
                  trackExcessSelection(index, e.target.value as VoluntaryExcessAmount);
                  try {
                    await quoteUpdaters.updatePetExcess(
                      index,
                      e.target.value as VoluntaryExcessAmount
                    );
                  } catch (error) {
                    defaultErrorHandling(error as AxiosError);
                  }
                }}
              />
            </QuestionFieldWithMargin>
            <LargeRichTextWithModal
              html={getExcessReplayText(pet)}
              pageTitle={pageTitle}
            />
            {getPetSpecificText(pet) && (
              <LargeRichTextWithModal
                html={getPetSpecificText(pet)}
                pageTitle={pageTitle}
              />
            )}
            {getDogAndCatText() && (
              <LargeRichTextWithModal html={getDogAndCatText()} pageTitle={pageTitle} />
            )}
            {getProductSpecificText(csContent.product_specific_text) && (
              <LargeRichTextWithModal
                html={getProductSpecificText(csContent.product_specific_text)}
                pageTitle={pageTitle}
              />
            )}
            {!isPet9YearsOrOlderOnDate(pet.dob, coverStartDate) &&
              !showMultipetSharedPetsOverNinePanel && (
                <PetsOverNineExcessPanel
                  data-cy="petsOverNineExcessPanel"
                  pageTitle={pageTitle}
                  icon={
                    showEmphasizedQuestions ? confirmExcessIcon ?? undefined : undefined
                  }
                  panelHeading={petNameReplacer(
                    pet.petName,
                    csContent.pets_over_nine.example_excess_panel.panel_heading
                  )}
                  chosenExcessAmount={formatStringInPoundsWithSign(
                    pet.voluntaryExcessAmount
                  )}
                  fixedExcessAmount={formatStringInPoundsWithSign(
                    VoluntaryExcessAmount.Excess_100
                  )}
                  totalExcessAmount={getTotalAge9ExcessAmount(pet.voluntaryExcessAmount)}
                  excessInformationText={substituteExcessAmountPetPlaceholders(
                    pet,
                    getProductSpecificText(
                      csContent.pets_over_nine.example_excess_panel
                        .excess_information_text_by_product
                    )
                  )}>
                  {showEmphasizedQuestions && (
                    <>
                      <FlexibleWidthDividerWithTopBottomMargin />
                      {confirmExcessQuestion}
                    </>
                  )}
                </PetsOverNineExcessPanel>
              )}
          </>
        );
      })}
      {/* This panel is specifically rendered whenever we have multiple pets on a quote they all have the same chosen excess */}
      {voluntaryExcessAmounts && showMultipetSharedPetsOverNinePanel && quote.petInfos && (
        <PetsOverNineExcessPanel
          data-cy="multipetSharedPetsOverNineExcessPanel"
          pageTitle={pageTitle}
          icon={showEmphasizedQuestions ? confirmExcessIcon ?? undefined : undefined}
          panelHeading={plainTextPlaceholderReplacer(
            csContent.pets_over_nine.example_excess_panel.multipet_panel_heading
          )}
          chosenExcessAmount={formatStringInPoundsWithSign(voluntaryExcessAmounts[0])}
          fixedExcessAmount={formatStringInPoundsWithSign(
            VoluntaryExcessAmount.Excess_100
          )}
          totalExcessAmount={getTotalAge9ExcessAmount(voluntaryExcessAmounts[0])}
          excessInformationText={substituteExcessAmountPetPlaceholders(
            quote.petInfos[0],
            getProductSpecificText(
              csContent.pets_over_nine.example_excess_panel
                .excess_information_text_by_product
            )
          )}>
          {showEmphasizedQuestions && (
            <>
              <FlexibleWidthDividerWithTopBottomMargin />
              {confirmExcessQuestion}
            </>
          )}
        </PetsOverNineExcessPanel>
      )}
      {!showEmphasizedQuestions && <>{confirmExcessQuestion}</>}
    </ExcessSectionWrapper>
  );
};

export default ChooseExcessSection;
