import PageWidthContainer from '@rsa-digital/evo-shared-components/components/PageWidthContainer';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import {
  regularFontWeight,
  semiBoldFontWeight,
} from '@rsa-digital/evo-shared-components/theme/MarksAndSpencer/fonts';
import styled from 'styled-components';
import RichTextWithModal from 'components/RichTextWithModal';

export const StyledHeading = styled.h2`
  margin-top: 0;
  margin-bottom: ${spacing(2)};
  ${fonts.paragraphLarge};
  & {
    font-weight: ${semiBoldFontWeight};
  }

  ${mediaQuery.tabletLandscape`
    & {
      font-weight: ${regularFontWeight};
    }
  `}
`;

export const StyledText = styled.p`
  margin-top: 0;
  margin-bottom: ${spacing(4)};
  ${fonts.paragraphLarge};
`;

export const Container = styled(PageWidthContainer)`
  background-color: ${colors.neutral05};

  padding-top: ${spacing(4)};
  padding-bottom: ${spacing(4)};
  margin-top: ${spacing(4)};

  ${mediaQuery.tabletLandscape`
    padding-top: ${spacing(6)};
  `}
`;

export const IconWrapper = styled.div`
  margin-bottom: ${spacing(2)};
`;

export const RichTextWithMargin = styled(RichTextWithModal)`
  margin-bottom: ${spacing(4)};

  p {
    ${fonts.paragraphSmall};
  }

  ${mediaQuery.tabletPortrait`
    p {
      ${fonts.paragraph};
    }
  `}
`;

export const RichTextWithTopMargin = styled(RichTextWithModal)`
  margin-top: ${spacing(4)};

  p {
    ${fonts.paragraphSmall};
  }

  ${mediaQuery.tabletPortrait`
    p {
      ${fonts.paragraph};
    }
  `}
`;
