import { ButtonStyledAsLink } from '@rsa-digital/evo-shared-components/components/Link/styles';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const StyledProductInfo = styled.div`
  ${mediaQuery.desktop`
    display: flex;
    p {
      border-right: 1px solid ${colors.neutral08};
      padding-right: ${spacing(2)};
      &:last-child {
        border: 0;
      }
    }
  `}

  p {
    ${fonts.paragraphLarge};
    line-height: 24px !important;
    margin: ${spacing(1)};
    & {
      color: ${colors.neutral08};
    }
  }
`;

export const StyledLinkText = styled(ButtonStyledAsLink)`
  ${fonts.paragraphSmall}
  text-decoration: underline;
  text-align: left;
  vertical-align: bottom;
  & {
    color: ${colors.neutral08};
  }
`;
