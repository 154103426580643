import { graphql, useStaticQuery } from 'gatsby';
import { CsIcon, CsLink } from 'types/contentStack';

export type CsComparisonTableHeaderRow = {
  left_column_heading: string;
  right_column_heading: string;
};

export type CsComparisonTableBlock = {
  row_heading: string;
  row_subheading?: string;
  plus_content: string;
  premier_content: string;
};

type PlusPremierComparisonModalContent = {
  modal_id: string;
  modal_heading: string;
  modal_subheading: string;
  table_headings: {
    plus_cover: string;
    premier_cover: string;
  };
  comparison_rows: CsComparisonTableBlock[];
  policy_document_links: {
    plus_heading: string;
    premier_heading: string;
    bullet_icon: [CsIcon];
    policy_wording: CsLink;
    ipid_plus: CsLink;
    ipid_premier: CsLink;
  };
};

type CsPlusPremierComparisonModal = {
  csPetPlusAndPremierCoverComparisonModal: PlusPremierComparisonModalContent;
};

const query = graphql`
  query {
    csPetPlusAndPremierCoverComparisonModal {
      modal_id
      modal_heading
      modal_subheading
      table_headings {
        plus_cover
        premier_cover
      }
      comparison_rows {
        row_heading
        row_subheading
        plus_content
        premier_content
      }
      policy_document_links {
        plus_heading
        premier_heading
        bullet_icon {
          icon_code
        }
        policy_wording {
          href
          title
        }
        ipid_plus {
          href
          title
        }
        ipid_premier {
          href
          title
        }
      }
    }
  }
`;

const usePlusPremierComparisonModalContent = (): PlusPremierComparisonModalContent =>
  useStaticQuery<CsPlusPremierComparisonModal>(query)
    .csPetPlusAndPremierCoverComparisonModal;

export default usePlusPremierComparisonModalContent;
