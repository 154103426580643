import { GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import { colors } from '@rsa-digital/evo-shared-components/theme/MarksAndSpencer/colors';
import { semiBoldFontWeight } from '@rsa-digital/evo-shared-components/theme/MarksAndSpencer/fonts';
import styled from 'styled-components';
import ExcessSegment from './ExcessSegment';
import { ExcessBar } from './ExcessSegment/styles';
import RichTextWithModal from '../../../RichTextWithModal';

export const ExcessExampleInfoPanel = styled.div`
  border: 1px solid ${colors.neutral03};
  margin: ${spacing(4)} 0 0;

  padding: ${spacing(3)} ${spacing(1)} ${spacing(4)};

  ${mediaQuery.tabletPortrait`
    padding: ${spacing(3)} ${spacing(3)} ${spacing(4)};
  `}
`;

export const ExcessExampleContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin-top: ${spacing(1)};
  min-width: inherit;

  ${mediaQuery.tabletPortrait`
    margin-top: 0;
  `}
`;

export const PanelHeading = styled.p`
  ${fonts.paragraphLarge};

  margin: 0;

  & {
    font-weight: ${semiBoldFontWeight};
  }
`;

export const ExcessAmount = styled.p`
  ${fonts.headingMedium};
  margin: 0 0 ${spacing(2)};
`;

export const ExcessPanelRichText = styled(RichText)`
  margin: ${spacing(2)} 0 0;
`;

export const StyledDiv = styled.div`
  text-align: center;
  padding: 0;

  ${mediaQuery.tabletPortrait`
    padding: ${spacing(5)} ${spacing(4)};
  `}

  ${mediaQuery.tabletLandscape`
   padding: ${spacing(4)} ${spacing(10)};
  `}
`;

export const MathSymbol = styled.span`
  ${fonts.headingXLarge};
  & {
    font-weight: ${semiBoldFontWeight};
  }

  margin: ${spacing(0.5)} ${spacing(0.5)} ${spacing(0)};

  ${mediaQuery.tabletPortrait`
    margin: ${spacing(5)} ${spacing(3)} ${spacing(0)};
  `}
`;

export const FixedExcessSegment = styled(ExcessSegment)`
  ${ExcessBar} {
    background-color: ${colors.neutral03};
  }
`;

export const InformationParagraph = styled(RichTextWithModal)`
  p {
    ${fonts.paragraph}

    ${mediaQuery.tabletPortrait`
      ${fonts.paragraph};
    `}
    
    ${mediaQuery.tabletLandscape`
      ${fonts.paragraphLarge};
    `}
  }

  p + p {
    margin-top: 0;
  }

  margin-top: ${spacing(2)};

  ${mediaQuery.tabletPortrait`
     margin-top: ${spacing(5)};
  `}
`;

export const IconWrapper = styled.div`
  display: none;

  ${mediaQuery.tabletPortrait`
      display:block;
      margin-right: ${spacing(3)};
  `}
`;

export const GridItemFlex = styled(GridItem)`
  ${mediaQuery.tabletPortrait`
    margin-left: 0;
  `}
  display: flex;
`;

export const ExcessContent = styled.span`
  width: 100%;
`;
