import {
  required,
  validateIf,
} from '@rsa-digital/evo-shared-components/helpers/forms/rules';
import { ValidationRules } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { graphql, useStaticQuery } from 'gatsby';
import { getProductFromQuoteOptions, Product } from 'helpers/productHelpers';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import { QuoteOptions } from 'state/formData/quoteOptions';

type CsAdditionalQuestionEssentialErrorMessages = {
  csPetQuoteSummaryOfYourNeedsV2: {
    additional_question_essential_cover: {
      error_messages: {
        no_selected: string;
        missing: string;
      };
    };
  };
};

const query = graphql`
  query {
    csPetQuoteSummaryOfYourNeedsV2 {
      additional_question_essential_cover {
        error_messages {
          no_selected
          missing
        }
      }
    }
  }
`;

export const useAdditionalQuestionEssentialRules = (): ValidationRules<QuoteOptions> => {
  const {
    csPetQuoteSummaryOfYourNeedsV2: { additional_question_essential_cover },
  } = useStaticQuery<CsAdditionalQuestionEssentialErrorMessages>(query);

  const quote = useCurrentQuote();

  const isEssentialCover = (): boolean =>
    getProductFromQuoteOptions(quote.quoteOptions) === Product.Essential;

  return {
    additionalQuestionEssentialCover: validateIf(() => isEssentialCover(), [
      required(additional_question_essential_cover.error_messages.missing),
      {
        validityCondition: (value) => value !== false,
        errorMessage: additional_question_essential_cover.error_messages.no_selected,
      },
    ]),
  };
};
