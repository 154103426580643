import { Option } from '@rsa-digital/evo-shared-components/components/Form/RadioInput';
import { OptionGroup } from '@rsa-digital/evo-shared-components/components/Form/RadioInput/GroupedRadioInput';
import { Grid, GridItemProps } from '@rsa-digital/evo-shared-components/components/Grid';
import { ComponentProps } from '@rsa-digital/evo-shared-components/helpers/componentProps';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import QuestionField from 'components/QuestionField';
import { PageTitle, trackCoverTypeSelection } from 'helpers/eventTracking';
import { useCurrentQuote, useUpdateQuoteOptions } from 'helpers/useCurrentQuote';
import useQuestionProcessor from 'helpers/useQuestionProcessor';
import {
  initialQuoteOptions,
  LifetimeOption,
  PlusCoverFeeLimit,
  PremierCoverFeeLimit,
} from 'state/formData/quoteOptions';
import { CsQuestion } from 'types/contentStack';
import { StyledGridItem, StyledGroupedRadioInput } from './styles';

type PlusAndPremierCoverDetailsQuestionProps = {
  errorText?: string;
} & ComponentProps;

type CsPlusAndPremierCoverDetails = {
  csPetQuoteSummaryDemandsAndNeedsQuestionsV2: {
    plus_and_premier_cover_details: CsQuestion & {
      selector_group_titles: {
        plus_group_title: string;
        premier_group_title: string;
      };
    };
  };
};

const query = graphql`
  query {
    csPetQuoteSummaryDemandsAndNeedsQuestionsV2 {
      plus_and_premier_cover_details {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
        }
        selector_group_titles {
          plus_group_title
          premier_group_title
        }
      }
    }
  }
`;

const AdditionalLifetimeProductsQuestion: React.FC<PlusAndPremierCoverDetailsQuestionProps> = ({
  errorText,
}) => {
  const {
    csPetQuoteSummaryDemandsAndNeedsQuestionsV2: { plus_and_premier_cover_details },
  } = useStaticQuery<CsPlusAndPremierCoverDetails>(query);
  const { processQuestion } = useQuestionProcessor(PageTitle.QuoteSummary);
  const plusAndPremierCoverDetailsQuestion = processQuestion(
    plus_and_premier_cover_details
  );

  const updateQuoteOptions = useUpdateQuoteOptions();
  const quote = useCurrentQuote();
  const quoteOptions = quote?.quoteOptions ?? initialQuoteOptions;
  const { plusCoverFeeLimit, premierCoverFeeLimit } = quoteOptions;

  const addtionalLifetimeProductsRadioButtonId =
    'additionalLifetimeProductsRadioButtonId';

  const questionFieldGridItemProps: GridItemProps = {
    desktop: 10,
    tabletLandscape: 10,
    tabletPortrait: 8,
  };

  const plusOptions: Option[] = [
    {
      name: '£2,000',
      value: PlusCoverFeeLimit.Limit_2000,
    },
    {
      name: '£3,000',
      value: PlusCoverFeeLimit.Limit_3000,
    },
  ];

  const premierOptions: Option[] = [
    {
      name: '£5,000',
      value: PremierCoverFeeLimit.Limit_5000,
    },
    {
      name: '£7,000',
      value: PremierCoverFeeLimit.Limit_7000,
    },
    {
      name: '£10,000',
      value: PremierCoverFeeLimit.Limit_10000,
    },
  ];

  const optionGroups: OptionGroup[] = [
    {
      name: 'Plus',
      options: plusOptions,
    },
    {
      name: 'Premier',
      options: premierOptions,
    },
  ];

  return (
    <Grid>
      <StyledGridItem desktop={10} tabletLandscape={10}>
        <QuestionField
          question={plusAndPremierCoverDetailsQuestion}
          errorText={errorText}
          gridItemProps={questionFieldGridItemProps}>
          <StyledGroupedRadioInput
            id={addtionalLifetimeProductsRadioButtonId}
            value={plusCoverFeeLimit || premierCoverFeeLimit}
            optionGroups={optionGroups}
            onChange={(e) => {
              if (
                Object.values(PlusCoverFeeLimit).some((value) => e.target.value === value)
              ) {
                updateQuoteOptions({
                  plusCoverFeeLimit: e.target.value as PlusCoverFeeLimit,
                  premierCoverFeeLimit: undefined,
                });
              } else if (
                Object.values(PremierCoverFeeLimit).some(
                  (value) => e.target.value === value
                )
              ) {
                updateQuoteOptions({
                  premierCoverFeeLimit: e.target.value as PremierCoverFeeLimit,
                  plusCoverFeeLimit: undefined,
                });
              }
              trackCoverTypeSelection(
                e.target.value as
                  | LifetimeOption
                  | PlusCoverFeeLimit
                  | PremierCoverFeeLimit
              );
            }}
          />
        </QuestionField>
      </StyledGridItem>
    </Grid>
  );
};

export default AdditionalLifetimeProductsQuestion;
