import styled from 'styled-components';
import { hideOnPrint } from 'helpers/cssPrintHelpers';

export const BannerContainer = styled.div`
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  /** 
  This needs to be less than 999 to ensure it does not overlap modals.
  (Otherwise an issue occurs with the session timeout modal on mobile)
  */
  z-index: 998;

  ${hideOnPrint}
`;
