import { calculateGridItemChildWidth } from '@rsa-digital/evo-shared-components/commonStyles/innerGridItemColumn';
import { FlexibleWidthDivider } from '@rsa-digital/evo-shared-components/components/Divider';
import {
  RadioItem,
  Wrapper,
} from '@rsa-digital/evo-shared-components/components/Form/RadioInput/styles';
import SegmentedSelector from '@rsa-digital/evo-shared-components/components/Form/SegmentedSelector';
import { StyledSegment } from '@rsa-digital/evo-shared-components/components/Form/SegmentedSelector/Segment/styles';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery, {
  stylePerBreakpoint,
} from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import { Breakpoint } from '@rsa-digital/evo-shared-components/theme';
import { semiBoldFontWeight } from '@rsa-digital/evo-shared-components/theme/MarksAndSpencer/fonts';
import styled, { css } from 'styled-components';
import QuestionField from 'components/QuestionField';
import RichTextWithModal from 'components/RichTextWithModal';

export const PetHeading = styled.h2`
  margin: ${spacing(4)} 0 0;
  ${fonts.headingSmall}
  & {
    font-weight: ${semiBoldFontWeight};
  }
`;

export const LargeRichTextWithModal = styled(RichTextWithModal)`
  p {
    ${fonts.paragraphLarge}

    ${mediaQuery.tabletPortrait`
      ${fonts.paragraph};
    `}
    
    ${mediaQuery.tabletLandscape`
      ${fonts.paragraphLarge};
    `}
  }

  margin-top: ${spacing(4)};
`;

export const ExcessSectionWrapper = styled.div`
  margin-bottom: ${spacing(1)};

  ${mediaQuery.tabletPortrait`
    margin-bottom: ${spacing(3)};
`}

  ${mediaQuery.tabletLandscape`
    margin-bottom: ${spacing(2)};
`}
`;

export const PetsOverNineRichText = styled(RichTextWithModal)`
  p {
    ${fonts.paragraphLarge}

    ${mediaQuery.tabletPortrait`
      ${fonts.paragraph};
    `}
    
    ${mediaQuery.tabletLandscape`
      ${fonts.paragraphLarge};
    `}
  }

  margin-top: ${spacing(2)};
`;

export const QuestionFieldWithMargin = styled(QuestionField)`
  margin-top: ${spacing(3)};
`;

const segmentedSelectorGridItemProps: { [T in Breakpoint]: number } = {
  desktop: 3,
  tabletLandscape: 3,
  tabletPortrait: 3,
  mobile: 4,
};

export const StyledSegmentedSelector = styled(SegmentedSelector)`
  ${stylePerBreakpoint(
    (breakpoint) => css`
      width: ${({ theme }) =>
        calculateGridItemChildWidth(
          theme.layout[breakpoint].columns,
          segmentedSelectorGridItemProps[breakpoint],
          breakpoint
        )};
    `
  )}

  && {
    ${StyledSegment} {
      padding: ${spacing(1.5)} ${spacing(1)};

      ${mediaQuery.tabletPortrait`
        padding: ${spacing(1.5)} ${spacing(3)};
      `}
    }
  }
`;

export const FlexibleWidthDividerWithTopBottomMargin = styled(FlexibleWidthDivider)`
  margin-top: ${spacing(3)};
  margin-bottom: ${spacing(3)};
`;

export const StyledQuestionField = styled(QuestionField)<{
  removeBottomMargin: boolean | undefined;
  addTopPadding?: boolean | undefined;
}>`
  margin-bottom: ${(p) => (p.removeBottomMargin ? 0 : spacing(3))};
  padding-top: ${(p) => (p.addTopPadding ? spacing(6) : 0)};
  ${Wrapper} {
    display: flex;
    flex-direction: column;
    ${mediaQuery.tabletPortrait`
    flex-direction: row;
  `}
    ${RadioItem} {
      max-width: ${spacing(34)};
      width: 100%;
      margin: ${spacing(2)} auto 0 auto;
      ${mediaQuery.tabletPortrait`
        max-width: ${spacing(32)};
        margin: ${spacing(2)} 0 0 0;
      `};
    }
    ${RadioItem}:not(:first-of-type) {
      ${mediaQuery.tabletPortrait`
      margin-left:${spacing(4)};
    `}
    }
  }
`;
