import { ButtonLink } from '@rsa-digital/evo-shared-components/components/Link';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import { semiBoldFontWeight } from '@rsa-digital/evo-shared-components/theme/MarksAndSpencer/fonts';
import styled from 'styled-components';
import RichTextWithModal from 'components/RichTextWithModal';
import { summaryOfCoverBodyLateralPaddings } from '../SummaryOfCoverBodyPart/styles';

export const PricingInfoRow = styled.div`
  &:not(:last-of-type) {
    border-bottom: 1px solid ${colors.neutral03};
  }

  ${summaryOfCoverBodyLateralPaddings};
  padding-top: ${spacing(3)};
  padding-bottom: ${spacing(3)};

  ${mediaQuery.tabletPortrait`
    padding-top: ${spacing(4)};
    padding-bottom: ${spacing(4)};
  `};
`;

export const DescriptionSingleLine = styled.p`
  ${fonts.paragraph};

  ${mediaQuery.tabletPortrait`
    ${fonts.paragraphLarge};
  `};

  & {
    font-weight: ${semiBoldFontWeight};
  }

  margin-top: 0;
`;

export const MultipetOverAndUnderNineStatement = styled.p`
  ${fonts.paragraph};
`;

export const DescriptionRichText = styled(RichTextWithModal)`
  margin-bottom: 0;

  h3,
  h4,
  h5 {
    margin-bottom: ${spacing(1)};
    margin-top: ${spacing(3)};

    ${mediaQuery.tabletPortrait`
      margin-top: ${spacing(2)};
    `};
  }
`;

export const DescriptionRichTextWithSmallFont = styled(RichTextWithModal)`
  margin-bottom: 0;

  h3,
  h4,
  h5 {
    margin-bottom: ${spacing(1)};
    margin-top: ${spacing(3)};

    ${mediaQuery.tabletPortrait`
      margin-top: ${spacing(2)};
    `};
  }
`;

export const PetExcess = styled.span`
  ${fonts.paragraphLarge};

  & {
    font-weight: ${semiBoldFontWeight};
  }

  &:not(:last-of-type) {
    padding-bottom: ${spacing(2)};
    border-bottom: solid 1px ${colors.neutral03};
  }

  &:not(:first-of-type) {
    padding-top: ${spacing(2)};
  }

  ${mediaQuery.tabletPortrait`
    &:not(:last-of-type) {
      border-bottom: none;
      padding-bottom: 0;

      padding-right: ${spacing(3)};
      border-right: solid 1px ${colors.neutral03};
    }

    &:not(:first-of-type) {
      padding-top: 0;

      padding-left: ${spacing(3)};
    }
  `};
`;

export const PetsExcesses = styled.div`
  display: flex;
  flex-direction: column;

  margin: ${spacing(3)} 0;

  ${mediaQuery.tabletPortrait`
    margin: ${spacing(2)} 0;
    flex-direction: row;
  `};
`;

export const ModalLinkText = styled(ButtonLink)`
  && {
    ${mediaQuery.tabletLandscape`
      ${fonts.paragraphLarge};
    `}
  }

  &&& {
    font-weight: ${semiBoldFontWeight};
  }
`;

export const ModalLinkTextWithTopMargin = styled(ModalLinkText)`
  margin-top: ${spacing(1)};

  ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(2)};
  `}
  text-align: left;
`;
