import { GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { IconWrapper } from '@rsa-digital/evo-shared-components/components/Icon/styles';
import Link from '@rsa-digital/evo-shared-components/components/Link';
import Modal from '@rsa-digital/evo-shared-components/components/Modal';
import { CloseButton } from '@rsa-digital/evo-shared-components/components/Modal/styles';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import {
  heavyFontWeight,
  semiBoldFontWeight,
} from '@rsa-digital/evo-shared-components/theme/MarksAndSpencer/fonts';
import styled from 'styled-components';
import PolicyLink from 'components/PolicyDocuments/PolicyLink';

export const ContentWrapper = styled.div`
  margin: 0 ${spacing(2)} ${spacing(4)};

  ${mediaQuery.tabletPortrait`
    margin: 0 0 ${spacing(4)};
  `}

  & thead {
    position: sticky;
    top: 0;
  }
`;

export const PolicyLinksHeading = styled.h3`
  padding: ${spacing(2)} 0;

  ${fonts.headingXSmall}

  & {
    font-weight: ${heavyFontWeight};
    color: ${colors.core02};
  }

  margin: ${spacing(6)} 0 ${spacing(3)};
`;

export const PolicyLinkWithMargin = styled(PolicyLink)`
  margin-right: ${spacing(1)};
  margin-top: ${spacing(3)};
`;

export const LinkWithMargin = styled(Link)`
  margin-bottom: ${spacing(2)};
  && {
    ${fonts.headingXSmall}
  }
`;

export const StyledModal = styled(Modal)`
  ${CloseButton} ${IconWrapper} {
    height: ${spacing(2)};
    width: ${spacing(2)};
  }
`;

export const ModalHeading = styled.h2`
  ${fonts.headingSmall};

  & {
    font-weight: ${semiBoldFontWeight};
  }
  margin: ${spacing(2)} ${spacing(2)} ${spacing(3)};

  ${mediaQuery.tabletPortrait`
      margin-left: 0;
  `}

  ${mediaQuery.tabletLandscape`
      margin-bottom: ${spacing(4)};
  `}
`;

export const ModalSubheading = styled.p`
  ${fonts.paragraph}

  margin: ${spacing(2)} ${spacing(2)} ${spacing(6)}};

  ${mediaQuery.tabletPortrait`
    margin: ${spacing(2)} ${spacing(0)} ${spacing(4)}};;
  `}

    ${mediaQuery.tabletLandscape`
      margin-bottom: ${spacing(3)};
  `}
`;

export const HeadingContainer = styled.div`
  text-align: center;
  border-right: 1px solid ${colors.core02};
  background-color: ${colors.core03};
`;

export const StyledGridItem = styled(GridItem)`
  margin: 0;

  ${mediaQuery.tabletPortrait`
    width: calc(50% - 8px);
`}

  ${mediaQuery.tabletLandscape`
    width: calc(50% - 16px);
`}
`;
