import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import Icon from '@rsa-digital/evo-shared-components/components/Icon';
import componentProps, {
  ComponentProps,
} from '@rsa-digital/evo-shared-components/helpers/componentProps';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { PageTitle } from 'helpers/eventTracking';
import ExcessSegment from './ExcessSegment';
import TotalExcessSegment from './TotalExcessSegment';
import {
  ExcessContent,
  ExcessExampleContainer,
  ExcessExampleInfoPanel,
  FixedExcessSegment,
  GridItemFlex,
  IconWrapper,
  InformationParagraph,
  MathSymbol,
  PanelHeading,
} from './styles';

type CsPetsOverNineExcessPanel = {
  csPetQuoteSummaryChooseExcessV2: {
    pets_over_nine: {
      example_excess_panel: {
        chosen_excess_text: string;
        fixed_excess_text: string;
        total_excess_text: string;
      };
    };
  };
};

const query = graphql`
  query {
    csPetQuoteSummaryChooseExcessV2 {
      pets_over_nine {
        example_excess_panel {
          chosen_excess_text
          fixed_excess_text
          total_excess_text
        }
      }
    }
  }
`;

type PetsOverNineExcessPanelProps = {
  pageTitle: PageTitle;
  panelHeading: string;
  chosenExcessAmount: string;
  fixedExcessAmount: string;
  totalExcessAmount: string;
  excessInformationText: string;
  icon?: string;
  children?: React.ReactNode;
} & ComponentProps;

const PetsOverNineExcessPanel: React.FC<PetsOverNineExcessPanelProps> = ({
  pageTitle,
  panelHeading,
  chosenExcessAmount,
  fixedExcessAmount,
  totalExcessAmount,
  excessInformationText,
  icon,
  children,
  ...rest
}) => {
  const csContent = useStaticQuery<CsPetsOverNineExcessPanel>(query)
    .csPetQuoteSummaryChooseExcessV2;

  return (
    <Grid alignLeft>
      <GridItem desktop={10} tabletLandscape={10}>
        <ExcessExampleInfoPanel {...componentProps(rest)}>
          <GridItemFlex>
            {icon && (
              <IconWrapper>
                <Icon size="large" name={icon} />
              </IconWrapper>
            )}
            <ExcessContent>
              <PanelHeading>{panelHeading}</PanelHeading>
              <ExcessExampleContainer>
                <ExcessSegment
                  excessAmount={chosenExcessAmount}
                  excessText={
                    csContent.pets_over_nine.example_excess_panel.chosen_excess_text
                  }
                />
                <MathSymbol>+</MathSymbol>
                <FixedExcessSegment
                  excessAmount={fixedExcessAmount}
                  excessText={
                    csContent.pets_over_nine.example_excess_panel.fixed_excess_text
                  }
                />
                <MathSymbol>=</MathSymbol>
                <TotalExcessSegment
                  excessAmount={totalExcessAmount}
                  excessText={
                    csContent.pets_over_nine.example_excess_panel.total_excess_text
                  }
                />
              </ExcessExampleContainer>
              <InformationParagraph html={excessInformationText} pageTitle={pageTitle} />
              {children}
            </ExcessContent>
          </GridItemFlex>
        </ExcessExampleInfoPanel>
      </GridItem>
    </Grid>
  );
};

export default PetsOverNineExcessPanel;
