import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const DiscountPanelContainer = styled.div`
  margin-top: ${spacing(4)};
  display: flex;

  padding: ${spacing(3)};

  border: 1px solid ${colors.neutral03};
  background-color: #c7d8e7;

  flex-direction: row;

  ${mediaQuery.tabletLandscape`
    padding: ${spacing(4)};
  `};
`;

export const IconWrapper = styled.div`
  margin-right: ${spacing(3)};
`;
