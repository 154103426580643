import componentProps, {
  ComponentProps,
} from '@rsa-digital/evo-shared-components/helpers/componentProps';
import React from 'react';
import { PageTitle } from 'helpers/eventTracking';
import { StyledPanel, StyledRichText } from './styles';

type ConfirmationBoxProps = {
  html: string;
  pageTitle: PageTitle;
} & ComponentProps;

const ConfirmationBox: React.FC<ConfirmationBoxProps> = ({
  html,
  pageTitle,
  ...rest
}) => {
  return (
    <StyledPanel borderShadows={{ displayOutset: true }} {...componentProps(rest)}>
      <StyledRichText html={html} pageTitle={pageTitle} />
    </StyledPanel>
  );
};

export default ConfirmationBox;
