import FlexibleWidthDivider from '@rsa-digital/evo-shared-components/components/Divider/FlexibleWidthDivider';
import {
  RadioItem,
  Wrapper,
} from '@rsa-digital/evo-shared-components/components/Form/RadioInput/styles';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import QuestionField from 'components/QuestionField';
import RichTextWithModal from 'components/RichTextWithModal';

export const FlexContainer = styled.div`
  display: flex;
  border: 1px solid ${colors.core01};
  padding: ${spacing(3)};

  margin: ${spacing(3)} 0 ${spacing(4)};

  ${mediaQuery.tabletPortrait`
    padding: ${spacing(3)};
  `}
`;

export const IconWrapper = styled.div`
  margin-right: ${spacing(2)};
`;

export const LargeRichText = styled(RichTextWithModal)`
  p {
    ${fonts.paragraphLarge};
  }
`;

export const FlexibleWidthDividerWithMargin = styled(FlexibleWidthDivider)`
  margin: ${spacing(3)} 0 ${spacing(3)} ${spacing(-1)};

  ${mediaQuery.tabletLandscape`
    margin-left: ${spacing(-2)};
  `}
`;

export const StyledQuestionField = styled(QuestionField)`
  margin-bottom: 0;
  ${Wrapper} {
    display: flex;
    flex-direction: column;
    ${mediaQuery.tabletPortrait`
    flex-direction: row;
  `}
    ${RadioItem} {
      max-width: ${spacing(34)};
      width: 100%;
      margin: ${spacing(2)} auto 0 auto;
      ${mediaQuery.tabletPortrait`
        max-width: ${spacing(32)};
        margin: ${spacing(2)} 0 0 0;
      `};
    }
    ${RadioItem}:not(:first-of-type) {
      ${mediaQuery.tabletPortrait`
      margin-left:${spacing(4)};
    `}
    }
  }
`;
