import SimplePanel from '@rsa-digital/evo-shared-components/components/Panel/SimplePanel';
import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import { lightFontWeight } from '@rsa-digital/evo-shared-components/theme/MarksAndSpencer/fonts';
import styled from 'styled-components';
import RichTextWithModal from 'components/RichTextWithModal';

export const StyledPanel = styled(SimplePanel)`
  border: none;
  border-left: solid 4px ${colors.notificationInfo};
  background-color: ${colors.notificationInfoLight};
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  padding: ${spacing(2)} ${spacing(3)} ${spacing(3)};
`;

export const StyledRichText = styled(RichTextWithModal)`
  ${RichText} {
    h3 {
      ${fonts.headingXSmall}
    }

    & > *:first-child {
      margin-top: 0;
    }
    & {
      font-weight: ${lightFontWeight};
    }
  }
`;
