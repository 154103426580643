import { graphql, useStaticQuery } from 'gatsby';
import { Product } from 'helpers/productHelpers';
import { CsIcon, CsQuestion } from 'types/contentStack';

export type CsAdditionalQuestionContent = {
  question_field: CsQuestion;
  info_panel: string;
  no_button_text: string;
  yes_button_text: string;
  icon: [CsIcon];
};

export type CsAdditionalQuestionSection = {
  csPetQuoteSummaryOfYourNeedsV2: {
    additional_question_standard_cover: CsAdditionalQuestionContent;
    additional_question_plus_2k_cover: CsAdditionalQuestionContent;
  };
};

const query = graphql`
  query {
    csPetQuoteSummaryOfYourNeedsV2 {
      additional_question_standard_cover {
        question_field {
          main_details {
            question_text
            explanatory_text
            tooltip {
              tooltip_text
              tooltip_open
              tooltip_close
            }
            alert_text
          }
        }
        info_panel
        no_button_text
        yes_button_text
        icon {
          icon_code
        }
      }
      additional_question_plus_2k_cover {
        question_field {
          main_details {
            question_text
            explanatory_text
            tooltip {
              tooltip_text
              tooltip_open
              tooltip_close
            }
            alert_text
          }
        }
        info_panel
        no_button_text
        yes_button_text
        icon {
          icon_code
        }
      }
    }
  }
`;

export const useAdditionalQuestionSectionContent = (): CsAdditionalQuestionSection =>
  useStaticQuery<CsAdditionalQuestionSection>(query);

export const getAdditionalQuestionSectionContentPerProduct = (
  additionalQuestionContent: CsAdditionalQuestionSection,
  product: Product
): CsAdditionalQuestionContent => {
  switch (product) {
    case Product.Standard:
      return additionalQuestionContent.csPetQuoteSummaryOfYourNeedsV2
        .additional_question_standard_cover;
    case Product.Plus:
      return additionalQuestionContent.csPetQuoteSummaryOfYourNeedsV2
        .additional_question_plus_2k_cover;
    default:
      throw new Error('Invalid product type');
  }
};

export default useAdditionalQuestionSectionContent;
