import componentProps, {
  ComponentProps,
} from '@rsa-digital/evo-shared-components/helpers/componentProps';
import React from 'react';
import {
  ExcessAmount,
  ExcessBar,
  ExcessRichText,
  ExcessSegmentContainer,
  ExcessSegmentTextContainer,
} from './styles';

export type ExcessSegmentProps = {
  excessAmount: string;
  excessText: string;
} & ComponentProps;

const ExcessSegment: React.FC<ExcessSegmentProps> = ({
  excessAmount,
  excessText,
  ...rest
}) => {
  return (
    <ExcessSegmentContainer {...componentProps(rest)}>
      <ExcessSegmentTextContainer>
        <ExcessAmount>{excessAmount}</ExcessAmount>
        <ExcessRichText html={excessText} />
      </ExcessSegmentTextContainer>
      <ExcessBar />
    </ExcessSegmentContainer>
  );
};

export default ExcessSegment;
